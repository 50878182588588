import React from "react"
import { components } from "react-select"

import "./style.scss"
import NewCustomSelect from "../NewCustomSelect"
import useNewRxFlow from "../../utils/useNewRxFlow"
import useNewRxFlowDesktop from "../../utils/useNewRxFlowDesktop";

const BrandSelect = ({ value, onChange, options, error, show, isDisabled, brand }) => {
  const isHydro = brand === 'hydro'
  const isSkyhy = brand === 'skyhy'
  const isNewRx = useNewRxFlow()
  const isNewRxDesktop = useNewRxFlowDesktop()

  const defaultOption = (optionsList, value) => {
    if (!optionsList || (!value && !show)) return ""

    return optionsList
      .reduce((result, { options }) => [...result, ...options], [])
      .find(({ id }) => id === value)
  }

  const filteredByBrandOptions = () => {
    const currentItem = ({ title }) => title?.toLowerCase()?.includes(brand?.toLowerCase());
    const otherItems = ({ title }) => !title?.toLowerCase()?.includes(brand?.toLowerCase());
    const hydroFilter = ({ title }) => title?.toLowerCase()?.includes("hydro");
    const skyhyFilter = ({ title }) => title?.toLowerCase()?.includes("skyhy");
    const hubbleFilter = ({ title }) => title?.toLowerCase()?.includes("hydro by") || title?.toLowerCase()?.includes("skyhy by") || title?.toLowerCase()?.includes("hubble classic");
    const ccOtherLinkFilter = ({ id }) => id === -1;

    const hydroOptions = options[0].options.filter(hydroFilter);
    const skyhyOptions = options[0].options.filter(skyhyFilter);
    const hubbleOptions = options[0].options.filter(option => hubbleFilter(option) && !currentItem(option));
    const ccOtherLink = options[0].options.filter(ccOtherLinkFilter);

    const currentOption = options[0].options.filter(currentItem);
    const newRxOtherOptions = options[0].options.filter(option => otherItems(option) && !hubbleFilter(option) && !ccOtherLinkFilter(option))
    if (currentOption[0]) {
      currentOption[0].current = true
    }
    newRxOtherOptions.forEach(item => (item.current = false))
    const otherOptions = isNewRx || isNewRxDesktop ? [...hubbleOptions, ...(newRxOtherOptions), ...ccOtherLink] : options[1]

    if (isNewRx || isNewRxDesktop) return [...currentOption, ...otherOptions]
    if (isHydro) return [...hydroOptions, ...skyhyOptions, otherOptions]
    if (isSkyhy) return [...skyhyOptions, ...hydroOptions, otherOptions]
  };
  const filteredOptions = isHydro || isSkyhy || isNewRx || isNewRxDesktop ? filteredByBrandOptions() : options

  return (
    <NewCustomSelect
      name="brand"
      value={defaultOption(options, value)}
      onChange={onChange}
      options={filteredOptions}
      placeholder={isNewRx && !isNewRxDesktop ? "Select Brand" : "Select Your Brand"}
      components={{ Menu, Option, SingleValue }}
      getOptionValue={option => option.id}
      isDisabled={isDisabled}
    />
  )
}

const Menu = props => (
  <components.Menu {...props}>{props.children}</components.Menu>
)

const Option = props => {
  const { title, title2, price, price2, current, sale_price } = props.data
  const isNewRx = useNewRxFlow()
  const isNewRxDesktop = useNewRxFlowDesktop()

  return (
    <components.Option {...props}>
      <span className="text h8 neutral ocean-blue">
        {title2 ? title2 : title} {price !== "" ? <> - <b> ${price2 ? parseFloat(price2).toFixed(2) : parseFloat(sale_price || price).toFixed(2)}</b> </> : ""}
        {!isNewRxDesktop && <br/>}
        <span className={`text h9 neutral currently-viewing-text ${isNewRxDesktop ? "new-rx-desktop" : ""}`}>{((isNewRx || isNewRxDesktop) && current) && "(currently viewing)"}</span>
      </span>
    </components.Option>
  )
}

const SingleValue = props => {
  const { title, title2, price, price2, sale_price } = props.data
  const isNewRx = useNewRxFlow()
  const isNewRxDesktop = useNewRxFlowDesktop()

  return <components.SingleValue {...props}>{title2 ? title2 : title} {((isNewRx || isNewRxDesktop) && price !== "") ? <> - <b> ${price2 ? parseFloat(price2).toFixed(2) : parseFloat(sale_price || price).toFixed(2)}</b> </> : ""}</components.SingleValue>
}

export default BrandSelect
