import React, { useState, useEffect, useContext, useMemo } from "react"

import { framesInfoDictionary } from "../../services/framesInfoDictionary"
import NewButton from "../NewButton"
import { colorTags } from "../../services/data"
import CartContext from "../../context/cartContext"
import { usePromo, promoStart, promoEnd } from "../../utils/usePromo"

import "./style.scss"
import useNewRxFlowDesktop from "../../utils/useNewRxFlowDesktop"
import { EnterRXWrapper } from "../RXComponent"
import NewCustomSelect from "../NewCustomSelect"
import { selectDefaultValue } from "../../services/utils"
import scrollTo from "gatsby-plugin-smoothscroll"
import {productInfo, productInfo as prescriptionData} from "../Intake/Prescription/mockedData"
import { useWindowWidth } from "../../services/hooks"
import NewRxFlow from "../NewRxFlow";
import avgProducts from "../../services/avg-products.json"
import {skus} from "../../services/upcAvg.js"
import NoProductModal from "../NoProductModal";
import {hubbleList} from "../ContactsPrescription/mockedData";
import { navigate } from 'gatsby'


const ProductDetails = ({
  item,
  currentOptionIndex,
  setCurrentOptionIndex,
  onClick,
  type,
  hydro,
  skyhy,
}) => {
  const { cartItems, currentItem, setIsAvg } = useContext(CartContext)
  const { title, options } = item
  const buttonCopy =
    type === "contacts"
      ? !hydro && !skyhy
        ? "Start Your Subscription for $1"
        : "Buy Now"
      : "Select Frames"
  const [framesInfo, setFramesInfo] = useState()
  const [headerType, setHeaderType] = useState("")
  const hubble_lenses = cartItems.filter(obj => {
    return obj.type === "contacts"
  })
  const isPromo = usePromo(promoStart, promoEnd)
  const isNewRxDesktop = useNewRxFlowDesktop()
  const currentOption = options[currentOptionIndex]
  const { sale_price: offPrice, price } = currentOption
  const width = useWindowWidth()
  const [isMobile, setIsMobile] = useState(false)
  const [show, setShow] = useState(false)
  const [showNoProduct, setShowNoProduct] = useState(false)

  useEffect(() => {
    if (width <= 1110 && !isMobile) setIsMobile(true)
    else if (width > 1110 && isMobile) setIsMobile(false)
  }, [width])

  const { order, setOrder, setCurrentItem } = useContext(CartContext)
  const prescriptionSettings = useMemo(
    () => prescriptionData[currentOption.cc_product_id],
    [prescriptionData, currentOption]
  )
  const [prescription, setPrescription] = useState({
    leftEye: {
      power: order?.prescription?.leftEye?.power,
      ...(order?.prescription?.leftEye?.cylinder && prescriptionSettings?.cylinder && {
        cylinder: order?.prescription?.leftEye?.cylinder
      }),
      ...(order?.prescription?.leftEye?.axis && prescriptionSettings?.axis && {
        axis: order?.prescription?.leftEye?.axis
      }),
      baseCurve: Array.isArray(prescriptionSettings?.base_curve_values) && prescriptionSettings?.base_curve_values.length > 1
        ? order?.prescription?.leftEye?.baseCurve
        : item.baseCurve,
      diameter: order?.prescription?.leftEye?.diameter || item.diameter,
    },
    rightEye: {
      power: order?.prescription?.rightEye?.power,
      ...(order?.prescription?.rightEye?.cylinder && prescriptionSettings?.cylinder && {
        cylinder: order?.prescription?.rightEye?.cylinder
      }),
      ...(order?.prescription?.rightEye?.axis && prescriptionSettings?.axis && {
        axis: order?.prescription?.rightEye?.axis
      }),
      baseCurve: Array.isArray(prescriptionSettings?.base_curve_values) && prescriptionSettings?.base_curve_values.length > 1
        ? order?.prescription?.rightEye?.baseCurve
        : item.baseCurve,
      diameter: order?.prescription?.rightEye?.diameter || item.diameter,
    },
  })

  const leftEyePowerOptions = [
    { value: 'oneEye', label: `I don't need left eye lenses` },
    ...prescriptionSettings.eye_powers
  ]

  const rightEyePowerOptions = [
    { value: 'oneEye', label: `I don't need right eye lenses` },
    ...prescriptionSettings.eye_powers
  ]

  const [rightPowerValue, setRightPowerValue] = useState(
    selectDefaultValue(rightEyePowerOptions, prescription?.rightEye?.power)
  )
  const [leftPowerValue, setLeftPowerValue] = useState(
    selectDefaultValue(leftEyePowerOptions, prescription?.leftEye?.power)
  )
  const [rightAxisValue, setRightAxisValue] = useState(
    selectDefaultValue(prescriptionSettings?.axis, prescription?.rightEye?.axis)
  )
  const [leftAxisValue, setLeftAxisValue] = useState(
    selectDefaultValue(prescriptionSettings?.axis, prescription?.leftEye?.axis)
  )
  const [rightCylinderValue, setRightCylinderValue] = useState(
    selectDefaultValue(prescriptionSettings?.cylinder, prescription?.rightEye?.cylinder)
  )
  const [leftCylinderValue, setLeftCylinderValue] = useState(
    selectDefaultValue(prescriptionSettings?.cylinder, prescription?.leftEye?.cylinder)
  )
  const [rightAddPowerValue, setRightAddPowerValue] = useState(
    selectDefaultValue(prescriptionSettings?.add_power, prescription?.rightEye?.addPower)
  )
  const [leftAddPowerValue, setLeftAddPowerValue] = useState(
    selectDefaultValue(prescriptionSettings?.add_power, prescription?.leftEye?.addPower)
  )
  const [rightBaseCurveValue, setRightBaseCurveValue] = useState(
    selectDefaultValue(prescriptionSettings?.base_curve_values, String(order?.prescription?.rightEye?.baseCurve))
  )
  const [leftBaseCurveValue, setLeftBaseCurveValue] = useState(
    selectDefaultValue(prescriptionSettings?.base_curve_values, String(order?.prescription?.leftEye?.baseCurve))
  )
  const [isEmptyValues, setIsEmptyValues] = useState(false)



  useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorage.removeItem("previousPage")
      const urlParams = new URLSearchParams(window?.location.search)
      const rxflow = urlParams.get("rxflow")
      const newRxFlow = urlParams.get("newRxFlow")
      if ((rxflow === "true" || newRxFlow === "true") && order?.prescription?.leftEye?.power && order?.prescription?.rightEye?.power) {
        setShow(true)
      }
    }
  }, []);

  useEffect(() => {
    // product that has all values
    // if (prescriptionSettings.axis && prescriptionSettings.add_power && prescriptionSettings.cylinder && prescriptionSettings.base_curve_values) {

    // }
    if (currentOption.sku !== "AVOA"){
      return
    }

    // product that has cylinder and axis
    if (prescriptionSettings.axis && prescriptionSettings.cylinder) {
      // when a value of the right hand side is finalized loop through and check
      if(rightAxisValue?.value !== undefined && rightPowerValue?.value !== undefined && rightCylinderValue?.value !== undefined) {
        let result = skus.findIndex(x => x.axis === rightAxisValue?.value && x.cyl === rightCylinderValue?.value && parseFloat(x.power) === parseFloat(rightPowerValue.value))
        if(result !== -1) {
          setShowNoProduct(true)
          resetFields()
        }
        // alert(rightAxisValue)
      }
      // when all fields for the left side are filled out loop through and check
      if(leftAxisValue?.value !== undefined && leftPowerValue?.value !== undefined && leftCylinderValue?.value !== undefined) {
        let result = skus.findIndex(x => x.axis === leftAxisValue?.value && x.cyl === leftCylinderValue?.value && parseFloat(x.power) === parseFloat(leftPowerValue.value))
        if(result !== -1) {
          setShowNoProduct(true)
          resetFields()
        }
        // alert(rightAxisValue)
      }
    }

    //product that only has base curve

    // if (prescriptionSettings.base_curve_values) {
    //   // when a value of the right hand side is finalized loop through and check
    //   if(rightBaseCurveValue?.value !== undefined && rightPowerValue?.value !== undefined) {
    //     let result = skus.findIndex(x => x.sku === currentOption?.sku && parseFloat(x.bc) === parseFloat(rightBaseCurveValue?.value) && parseFloat(x.power) === parseFloat(rightPowerValue.value))
    //     if(result === -1) {
    //       setShowNoProduct(true)
    //     }
    //     // alert(rightAxisValue)
    //   }
    //   // when all fields for the left side are filled out loop through and check
    //   if(leftBaseCurveValue?.value !== undefined && leftPowerValue?.value !== undefined ) {
    //     let result = skus.findIndex(x => x.sku === currentOption?.sku && parseFloat(x.bc) === parseFloat(leftBaseCurveValue?.value) && parseFloat(x.power) === parseFloat(leftPowerValue.value))
    //     if(result === -1) {
    //       setShowNoProduct(true)
    //     }
    //     // alert(rightAxisValue)
    //   }
    // }
  },[rightAxisValue,rightPowerValue,rightCylinderValue, leftAxisValue, leftPowerValue,leftCylinderValue])

  useEffect(() => {
    const currentItem = framesInfoDictionary.find(obj => {
      return obj.title === title
    })
    setFramesInfo(currentItem)
  }, [title])

  useEffect(() => {
    if (type === "glasses") {
      setHeaderType("Eyeglasses")
    } else if (type === "sunglasses") {
      setHeaderType("Sunglasses")
    }
  }, [type])

  useEffect(() => {
    setPrescription({
      leftEye: {
        ...order?.prescription?.leftEye,
        power: order?.prescription?.leftEye?.power,
        baseCurve: Array.isArray(prescriptionSettings?.base_curve_values) && prescriptionSettings?.base_curve_values.length > 1
          ? order?.prescription?.leftEye?.baseCurve
          : item.baseCurve,
        diameter: item.diameter,
      },
      rightEye: {
        ...order?.prescription?.rightEye,
        power: order?.prescription?.rightEye?.power,
        baseCurve: Array.isArray(prescriptionSettings?.base_curve_values) && prescriptionSettings?.base_curve_values.length > 1
          ? order?.prescription?.rightEye?.baseCurve
          : item.baseCurve,
        diameter: item.diameter,
      },
    })

    if (!order?.prescription?.rightEye?.power) setRightPowerValue(null)
    if (!order?.prescription?.leftEye?.power) setLeftPowerValue(null)
    if (!order?.prescription?.rightEye?.axis) setRightAxisValue(null)
    if (!order?.prescription?.leftEye?.axis) setLeftAxisValue(null)
    if (!order?.prescription?.rightEye?.cylinder) setRightCylinderValue(null)
    if (!order?.prescription?.leftEye?.cylinder) setLeftCylinderValue(null)
    if (!order?.prescription?.rightEye?.addPower) setRightAddPowerValue(null)
    if (!order?.prescription?.leftEye?.addPower) setLeftAddPowerValue(null)
    if (!order?.prescription?.rightEye?.baseCurve) setRightBaseCurveValue(null)
    if (!order?.prescription?.leftEye?.baseCurve) setLeftBaseCurveValue(null)

    if (
      order?.prescription?.rightEye?.power === "oneEye" ||
      order?.prescription?.leftEye?.power === "oneEye"
    ) {
      setOrder({ ...order, isOneEye: true })
    } else if (!["leftEye", "rightEye"].includes(order?.isOneEye)) {
      setOrder({ ...order, isOneEye: false })
    }
  }, [currentItem])

  useEffect(() => {
    if (hubble_lenses.length === 0)
      resetFields()
  }, [currentOption]);

  const resetFields = () => {
    setRightPowerValue(null)
    setLeftPowerValue(null)
    setRightAxisValue(null)
    setLeftAxisValue(null)
    setRightCylinderValue(null)
    setLeftCylinderValue(null)
    setRightAddPowerValue(null)
    setLeftAddPowerValue(null)
    setRightBaseCurveValue(null)
    setLeftBaseCurveValue(null)

    const emptyPrescription = {
      leftEye: {
        power: undefined,
        baseCurve: Array.isArray(prescriptionSettings?.base_curve_values) && prescriptionSettings?.base_curve_values.length > 1
          ? undefined
          : item.baseCurve,
        diameter: item.diameter,
      },
      rightEye: {
        power: undefined,
        baseCurve: Array.isArray(prescriptionSettings?.base_curve_values) && prescriptionSettings?.base_curve_values.length > 1
          ? undefined
          : item.baseCurve,
        diameter: item.diameter,
      },
    }

    setPrescription(emptyPrescription)
    setOrder({...order, prescription: emptyPrescription})
    setCurrentItem(null)
  }

  const handlePowerChange = (eye, value) => {
    if (eye === "rightEye")
      setRightPowerValue(selectDefaultValue(rightEyePowerOptions, value))
    if (eye === "leftEye")
      setLeftPowerValue(selectDefaultValue(leftEyePowerOptions, value))
    const isOneEye = value === "oneEye"
    const newEye = { ...prescription[eye], power: value }
    const newPrescription = { ...prescription, [eye]: newEye }
    const newOrder = { ...order, prescription: newPrescription, isOneEye }
    setOrder(newOrder)
    setPrescription(newPrescription)
  }

  const handleAxisChange = (eye, value) => {
    if (eye === "leftEye") {
      setLeftAxisValue(selectDefaultValue(prescriptionSettings.axis, value))
    }
    else {
      setRightAxisValue(selectDefaultValue(prescriptionSettings.axis, value))
    }

    const newPrescription = {
      ...prescription,
      [eye]: {
        ...prescription[eye],
        axis: value
      }
    }

    setPrescription(newPrescription)
    setOrder({...order, prescription: newPrescription})
  }

  const handleCylinderChange = (eye, value) => {
    if (eye === "leftEye") {
      setLeftCylinderValue(selectDefaultValue(prescriptionSettings.cylinder, value))
    }
    else {
      setRightCylinderValue(selectDefaultValue(prescriptionSettings.cylinder, value))
    }

    const newPrescription = {
      ...prescription,
      [eye]: {
        ...prescription[eye],
        cylinder: value
      }
    }

    setPrescription(newPrescription)
    setOrder({...order, prescription: newPrescription})
  }

  const handleAddPowerChange = (eye, value) => {
    if (eye === "leftEye") {
      setLeftAddPowerValue(selectDefaultValue(prescriptionSettings.add_power, value))
    }
    else {
      setRightAddPowerValue(selectDefaultValue(prescriptionSettings.add_power, value))
    }

    const newPrescription = {
      ...prescription,
      [eye]: {
        ...prescription[eye],
        addPower: value
      }
    }

    setPrescription(newPrescription)
    setOrder({...order, prescription: newPrescription})
  }

  const handleBaseCurveChange = (eye, value) => {
    if (eye === "leftEye") {
      setLeftBaseCurveValue(selectDefaultValue(prescriptionSettings.base_curve_values, value))
    }
    else {
      setRightBaseCurveValue(selectDefaultValue(prescriptionSettings.base_curve_values, value))
    }

    const newPrescription = {
      ...prescription,
      [eye]: {
        ...prescription[eye],
        baseCurve: value
      }
    }

    setPrescription(newPrescription)
    setOrder({...order, prescription: newPrescription})
  }

  const handleBuyBtn = () => {
    if (
      prescription?.rightEye?.power === "oneEye" &&
        (
          prescriptionSettings.axis && !leftAxisValue ||
          prescriptionSettings.cylinder && !leftCylinderValue ||
          prescriptionSettings.add_power && !leftAddPowerValue ||
          Array.isArray(prescriptionSettings.base_curve_values) &&
            prescriptionSettings.base_curve_values.length > 1 &&
            !leftBaseCurveValue
        ) ||
      prescription?.leftEye?.power === "oneEye" &&
      (
        prescriptionSettings.axis && !rightAxisValue ||
        prescriptionSettings.cylinder && !rightCylinderValue ||
        prescriptionSettings.add_power && !rightAddPowerValue ||
        Array.isArray(prescriptionSettings.base_curve_values) &&
          prescriptionSettings.base_curve_values.length > 1 &&
          !rightBaseCurveValue
      ) ||
      prescription?.leftEye?.power !== "oneEye" && prescription?.rightEye?.power !== "oneEye" &&
      (
        prescriptionSettings.axis && (!leftAxisValue || !rightAxisValue) ||
        prescriptionSettings.cylinder && (!leftCylinderValue || !rightCylinderValue) ||
        prescriptionSettings.add_power && (!leftAddPowerValue || !rightAddPowerValue) ||
        Array.isArray(prescriptionSettings.base_curve_values) && prescriptionSettings.base_curve_values.length > 1 &&
        (!leftBaseCurveValue || !rightBaseCurveValue)
      ) ||
      !(leftPowerValue && rightPowerValue) ||
      (prescription?.rightEye?.power === "oneEye" &&
        prescription?.leftEye?.power === "oneEye")
    ) {
      setIsEmptyValues(true)
      scrollTo(".power-row", "center")
      return
    }

    setIsEmptyValues(false)
    setShow(true)
    setIsAvg(true)
    onClick && onClick()
  }

  const banner =
    typeof document !== "undefined"
      ? document.querySelector(".discount-banner")
      : null

  const handleBrandChange = brandIndex => {
    setCurrentOptionIndex(brandIndex)
    navigate(brandIndex > 0 ? '#90' : '#30')
  }

  const validateBrandPrescription = (item, prescription) => {
    const hubbleBrand =
      item.title.toLowerCase().includes("hubble classic") && "default" ||
      item.title.toLowerCase().includes("skyhy by") && "skyhy" ||
      item.title.toLowerCase().includes("hydro by") && "hydro"
    const prescriptionSettings = {...productInfo[hubbleBrand || item.cc_product_id]};
    prescriptionSettings.eye_powers = [
      { value: 'oneEye', label: `` },
      ...prescriptionSettings.eye_powers
    ]
    const eyes = [prescription.leftEye, prescription.rightEye]

    if (eyes[0].power === "oneEye" && eyes[1].power === "oneEye") return false;

    return eyes.every(eye => {
      if (eye.power === "oneEye") return true;

      return Object.entries(eye).every(([key, value]) => {
        let customKeys = {
          "power": "eye_powers",
          "addPower": "add_power",
          "baseCurve": "base_curve_values"
        }

        let _key = customKeys[key] || key;

        if (typeof prescriptionSettings[_key] === "object")
          if (typeof prescriptionSettings[_key][0] === "object") {
            return prescriptionSettings[_key].some(({value: settingsValue}) => settingsValue == value)
          } else {
            return prescriptionSettings[_key].some((settingsValue) => settingsValue == value)
          }
        else
          return prescriptionSettings[_key] == value
      })
    })
  }

  return (
    <div
      className={`product-details-container-avg ${
        !!banner ? "include-banner-height" : ""
      }`}
      data-type={type}
    >
      <div
        className={`product-details ${isNewRxDesktop ? "new-rx-desktop" : ""}`}
      >
        {!isMobile && (
          <h1 className="text h3 heading">
            {title} {headerType}
          </h1>
        )}
        <div className="rx-wrapper">
          {options.length === 1 ? (
            <div className="price-container">
              {options.length === 1 && (
                <>
                  <h4 className="lenses-subheader">
                    {currentOption.quantity} lenses
                  </h4>
                  <span className="text h7 price">
                    {offPrice ? (
                      <>
                        <s>${Number(price).toFixed(2)}/eye</s>{" "}
                        <b>
                          ${Number(offPrice).toFixed(2)}/eye {!isMobile && `(${currentOption.percent_off}% off)`}
                        </b>
                      </>
                    ) : (
                      <>${Number(price).toFixed(2)}</>
                    )}
                  </span>
                </>
              )}
            </div>
          ) : (
            <EnterRXWrapper title="Choose Your Pack Size" color="purple">
              <div className="pack-options">
                {options.map((option, i) => (
                  <PackRadio
                    item={option}
                    active={currentOptionIndex === i}
                    onClick={() => handleBrandChange(i)}
                  />
                ))}
              </div>
            </EnterRXWrapper>
          )}

          <EnterRXWrapper title="Enter Your Prescription" color="purple">
            {prescriptionSettings?.eye_powers && (
              <div className="power-row">
                <div className="select-container">
                  <p className="title text h8 bulky ocean-blue">
                    Right Eye (OD) Power
                  </p>
                  <NewCustomSelect
                    value={rightPowerValue}
                    name="power"
                    onChange={({ value }) =>
                      handlePowerChange("rightEye", value)
                    }
                    options={rightEyePowerOptions}
                    placeholder={"Select"}
                    error={
                      !prescription.rightEye.power &&
                      prescription.rightEye.power !== "oneEye" &&
                      isEmptyValues
                    }
                  />
                </div>
                <div className="select-container">
                  <p className="title text h8 bulky ocean-blue">
                    Left Eye (OS) Power
                  </p>
                  <NewCustomSelect
                    value={leftPowerValue}
                    name="power"
                    onChange={({ value }) =>
                      handlePowerChange("leftEye", value)
                    }
                    options={leftEyePowerOptions}
                    placeholder={"Select"}
                    error={
                      !prescription.leftEye.power &&
                      prescription.leftEye.power !== "oneEye" &&
                      isEmptyValues
                    }
                  />
                </div>
              </div>
            )}

            {prescriptionSettings?.add_power && (
              <div className="power-row">
                <div className="select-container">
                  <p className="title text h8 bulky ocean-blue">
                    Right Eye (OD) Add Power
                  </p>
                  <NewCustomSelect
                    value={rightAddPowerValue}
                    name="addPower"
                    onChange={({ value }) =>
                      handleAddPowerChange("rightEye", value)
                    }
                    options={prescriptionSettings.add_power}
                    placeholder="Select"
                    error={
                      !prescription.rightEye.add_power &&
                      prescription.rightEye.power !== "oneEye" &&
                      isEmptyValues
                    }
                    isDisabled={prescription.rightEye.power === "oneEye"}
                  />
                </div>
                <div className="select-container">
                  <p className="title text h8 bulky ocean-blue">
                    Left Eye (OS) Add Power
                  </p>
                  <NewCustomSelect
                    value={leftAddPowerValue}
                    name="addPower"
                    onChange={({ value }) =>
                      handleAddPowerChange("leftEye", value)
                    }
                    options={prescriptionSettings.add_power}
                    placeholder="Select"
                    error={
                      !prescription.leftEye.add_power &&
                      prescription.leftEye.power !== "oneEye" &&
                      isEmptyValues
                    }
                    isDisabled={prescription.leftEye.power === "oneEye"}
                  />
                </div>
              </div>
            )}

            {prescriptionSettings?.cylinder && (
              <div className="power-row">
                <div className="select-container">
                  <p className="title text h8 bulky ocean-blue">
                    Right Eye (OD) Cylinder
                  </p>
                  <NewCustomSelect
                    value={rightCylinderValue}
                    name="cylinder"
                    onChange={({ value }) =>
                      handleCylinderChange("rightEye", value)
                    }
                    options={prescriptionSettings.cylinder}
                    placeholder="Select"
                    error={
                      !prescription.rightEye.cylinder &&
                      prescription.rightEye.power !== "oneEye" &&
                      isEmptyValues
                    }
                    isDisabled={prescription.rightEye.power === "oneEye"}
                  />
                </div>
                <div className="select-container">
                  <p className="title text h8 bulky ocean-blue">
                    Left Eye (OS) Cylinder
                  </p>
                  <NewCustomSelect
                    value={leftCylinderValue}
                    name="cylinder"
                    onChange={({ value }) =>
                      handleCylinderChange("leftEye", value)
                    }
                    options={prescriptionSettings.cylinder}
                    placeholder="Select"
                    error={
                      !prescription.leftEye.cylinder &&
                      prescription.leftEye.power !== "oneEye" &&
                      isEmptyValues
                    }
                    isDisabled={prescription.leftEye.power === "oneEye"}
                  />
                </div>
              </div>
            )}

            {prescriptionSettings?.axis && (
              <div className="power-row">
                <div className="select-container">
                  <p className="title text h8 bulky ocean-blue">
                    Right Eye (OD) Axis
                  </p>
                  <NewCustomSelect
                    value={rightAxisValue}
                    name="axis"
                    onChange={({ value }) =>
                      handleAxisChange("rightEye", value)
                    }
                    options={prescriptionSettings.axis}
                    placeholder="Select"
                    error={
                      !prescription.rightEye.axis &&
                      prescription.rightEye.power !== "oneEye" &&
                      isEmptyValues
                    }
                    isDisabled={prescription.rightEye.power === "oneEye"}
                  />
                </div>
                <div className="select-container">
                  <p className="title text h8 bulky ocean-blue">
                    Left Eye (OS) Axis
                  </p>
                  <NewCustomSelect
                    value={leftAxisValue}
                    name="axis"
                    onChange={({ value }) => handleAxisChange("leftEye", value)}
                    options={prescriptionSettings.axis}
                    placeholder="Select"
                    error={
                      !prescription.leftEye.axis &&
                      prescription.leftEye.power !== "oneEye" &&
                      isEmptyValues
                    }
                    isDisabled={prescription.leftEye.power === "oneEye"}
                  />
                </div>
              </div>
            )}

            {prescriptionSettings?.base_curve_values && Array.isArray(prescriptionSettings.base_curve_values) &&
              prescriptionSettings.base_curve_values.length > 1 && (
              <div className="power-row">
                <div className="select-container">
                  <p className="title text h8 bulky ocean-blue">
                    Right Eye (OD) Base Curve
                  </p>
                  <NewCustomSelect
                    value={rightBaseCurveValue}
                    name="baseCurve"
                    onChange={({ value }) =>
                      handleBaseCurveChange("rightEye", value)
                    }
                    options={prescriptionSettings.base_curve_values}
                    placeholder="Select"
                    error={
                      !prescription.rightEye.baseCurve &&
                      prescription.rightEye.power !== "oneEye" &&
                      isEmptyValues
                    }
                    isDisabled={prescription.rightEye.power === "oneEye"}
                  />
                </div>
                <div className="select-container">
                  <p className="title text h8 bulky ocean-blue">
                    Left Eye (OS) Base Curve
                  </p>
                  <NewCustomSelect
                    value={leftBaseCurveValue}
                    name="baseCurve"
                    onChange={({ value }) => handleBaseCurveChange("leftEye", value)}
                    options={prescriptionSettings.base_curve_values}
                    placeholder="Select"
                    error={
                      !prescription.leftEye.baseCurve &&
                      prescription.leftEye.power !== "oneEye" &&
                      isEmptyValues
                    }
                    isDisabled={prescription.leftEye.power === "oneEye"}
                  />
                </div>
              </div>
            )}
          </EnterRXWrapper>

          <NoProductModal show={showNoProduct} onHide={() => setShowNoProduct(false)} />
        </div>
        <NewButton
          key={isMobile ? "btn-mobile" : "btn-desktop"} // needed for the proper rerender with a sticky effect
          label="Continue to Checkout"
          color={colorTags[type]}
          onClick={handleBuyBtn}
          className="w-100"
          stickyScroll={isMobile}
          disabled={hubble_lenses?.length !== 0}
        />
        {(options.length + Object.values(prescriptionSettings).filter(e => Array.isArray(e) && e.length > 1).length < 4 || isMobile) && <ProductDescriptionAvg item={item} />}

        <NewRxFlow
          optionsList={[{options: [...avgProducts.flatMap(e => e.options), {
              id: -1,
              title: "Browse 50+ more brands at contactscart.com",
              price: "",
              value: -1,
              link: "https://www.contactscart.com/",
            }, ...hubbleList()]}]}
          show={show}
          setShow={setShow}
          brand={currentOption.title}
          onHide={() => setShow(false)}
          validateBrandPrescription={validateBrandPrescription}
          isAvg
        />
      </div>
    </div>
  )
}

const PackRadio = ({ item, active, onClick }) => {
  const { sale_price: offPrice, price, quantity } = item

  return (
    <div className="pack-radio" data-active={Boolean(active)} onClick={onClick}>
      <span className="radio-icon"></span>
      <div className="description">
        <span className="quantity">{quantity} lenses</span>
        <span className="price">
          {offPrice ? (
            <>
              <s>${Number(price).toFixed(2)}/eye</s>{" "}
              <b>${Number(offPrice).toFixed(2)}/eye (35% off) </b>
            </>
          ) : (
            <>${Number(price).toFixed(2)}</>
          )}
        </span>
      </div>
    </div>
  )
}

export const ProductDescriptionAvg = ({ item }) => {
  const width = useWindowWidth()
  const [isMobile, setIsMobile] = useState(false)
  const { baseCurve, diameter, material, manufacturer, description } = item

  const prescriptionSettings = useMemo(
    () => prescriptionData[item.options[0].cc_product_id],
    [prescriptionData, item]
  )
  const { base_curve_values: baseCurvePrescription } = prescriptionSettings;

  useEffect(() => {
    if (width <= 1110 && !isMobile) setIsMobile(true)
    else if (width > 1110 && isMobile) setIsMobile(false)
  }, [width])

  return (
    <div className="description-container" data-type="contacts">
      <DescriptionItem title="Base Curve (BC)" type="measurements">
        <span className="text h7 neue">{
          Array.isArray(baseCurvePrescription) && baseCurvePrescription.length > 1
            ? `${baseCurvePrescription[0].label} or ${baseCurvePrescription[1].label}`
            : baseCurve}
        </span>
      </DescriptionItem>
      {isMobile ? <>
        <DescriptionItem title="Material" type="measurements">
          <span className="text h7 neue">{material}</span>
        </DescriptionItem>
        <DescriptionItem title="Diameter (DIA)" type="measurements">
          <span className="text h7 neue">{diameter}</span>
        </DescriptionItem>
        <DescriptionItem title="Manufacturer" type="measurements">
          <span className="text h7 neue">{manufacturer}</span>
        </DescriptionItem>
        </>:<>
        <DescriptionItem title="Diameter (DIA)" type="measurements">
          <span className="text h7 neue">{diameter}</span>
        </DescriptionItem>
        <DescriptionItem title="Manufacturer" type="measurements">
          <span className="text h7 neue">{manufacturer}</span>
        </DescriptionItem>
        <DescriptionItem title="Material" type="measurements" className="grow">
          <span className="text h7 neue">{material}</span>
        </DescriptionItem>
      </>}
      <div className="description">{description}</div>
    </div>
  )
}

const DescriptionItem = ({ title, children, type, className }) => (
  <div className={`description-item ${className || ""}`} data-type={type}>
    <p className="description-head text h11 neue-bold uppercase">{title}</p>
    <div className="description-body">{children}</div>
  </div>
)

export default ProductDetails



