export const skus = [
  {power: "0.25", axis: "30", cyl: "-0.75"},
  {power: "0.5", axis: "30", cyl: "-0.75"},
  {power: "0.75", axis: "30", cyl: "-0.75"},
  {power: "1", axis: "30", cyl: "-0.75"},
  {power: "1.25", axis: "30", cyl: "-0.75"},
  {power: "1.5", axis: "30", cyl: "-0.75"},
  {power: "1.75", axis: "30", cyl: "-0.75"},
  {power: "2", axis: "30", cyl: "-0.75"},
  {power: "2.25", axis: "30", cyl: "-0.75"},
  {power: "2.5", axis: "30", cyl: "-0.75"},
  {power: "2.75", axis: "30", cyl: "-0.75"},
  {power: "3", axis: "30", cyl: "-0.75"},
  {power: "3.25", axis: "30", cyl: "-0.75"},
  {power: "3.5", axis: "30", cyl: "-0.75"},
  {power: "3.75", axis: "30", cyl: "-0.75"},
  {power: "4", axis: "30", cyl: "-0.75"},
  {power: "4.25", axis: "30", cyl: "-0.75"},
  {power: "4.5", axis: "30", cyl: "-0.75"},
  {power: "4.75", axis: "30", cyl: "-0.75"},
  {power: "5", axis: "30", cyl: "-0.75"},
  {power: "5.25", axis: "30", cyl: "-0.75"},
  {power: "5.5", axis: "30", cyl: "-0.75"},
  {power: "5.75", axis: "30", cyl: "-0.75"},
  {power: "6", axis: "30", cyl: "-0.75"},
  {power: "0.25", axis: "40", cyl: "-0.75"},
  {power: "0.5", axis: "40", cyl: "-0.75"},
  {power: "0.75", axis: "40", cyl: "-0.75"},
  {power: "1", axis: "40", cyl: "-0.75"},
  {power: "1.25", axis: "40", cyl: "-0.75"},
  {power: "1.5", axis: "40", cyl: "-0.75"},
  {power: "1.75", axis: "40", cyl: "-0.75"},
  {power: "2", axis: "40", cyl: "-0.75"},
  {power: "2.25", axis: "40", cyl: "-0.75"},
  {power: "2.5", axis: "40", cyl: "-0.75"},
  {power: "2.75", axis: "40", cyl: "-0.75"},
  {power: "3", axis: "40", cyl: "-0.75"},
  {power: "3.25", axis: "40", cyl: "-0.75"},
  {power: "3.5", axis: "40", cyl: "-0.75"},
  {power: "3.75", axis: "40", cyl: "-0.75"},
  {power: "4", axis: "40", cyl: "-0.75"},
  {power: "4.25", axis: "40", cyl: "-0.75"},
  {power: "4.5", axis: "40", cyl: "-0.75"},
  {power: "4.75", axis: "40", cyl: "-0.75"},
  {power: "5", axis: "40", cyl: "-0.75"},
  {power: "5.25", axis: "40", cyl: "-0.75"},
  {power: "5.5", axis: "40", cyl: "-0.75"},
  {power: "5.75", axis: "40", cyl: "-0.75"},
  {power: "6", axis: "40", cyl: "-0.75"},
  {power: "0.25", axis: "50", cyl: "-0.75"},
  {power: "0.5", axis: "50", cyl: "-0.75"},
  {power: "0.75", axis: "50", cyl: "-0.75"},
  {power: "1", axis: "50", cyl: "-0.75"},
  {power: "1.25", axis: "50", cyl: "-0.75"},
  {power: "1.5", axis: "50", cyl: "-0.75"},
  {power: "1.75", axis: "50", cyl: "-0.75"},
  {power: "2", axis: "50", cyl: "-0.75"},
  {power: "2.25", axis: "50", cyl: "-0.75"},
  {power: "2.5", axis: "50", cyl: "-0.75"},
  {power: "2.75", axis: "50", cyl: "-0.75"},
  {power: "3", axis: "50", cyl: "-0.75"},
  {power: "3.25", axis: "50", cyl: "-0.75"},
  {power: "3.5", axis: "50", cyl: "-0.75"},
  {power: "3.75", axis: "50", cyl: "-0.75"},
  {power: "4", axis: "50", cyl: "-0.75"},
  {power: "4.25", axis: "50", cyl: "-0.75"},
  {power: "4.5", axis: "50", cyl: "-0.75"},
  {power: "4.75", axis: "50", cyl: "-0.75"},
  {power: "5", axis: "50", cyl: "-0.75"},
  {power: "5.25", axis: "50", cyl: "-0.75"},
  {power: "5.5", axis: "50", cyl: "-0.75"},
  {power: "5.75", axis: "50", cyl: "-0.75"},
  {power: "6", axis: "50", cyl: "-0.75"},
  {power: "0.25", axis: "60", cyl: "-0.75"},
  {power: "0.5", axis: "60", cyl: "-0.75"},
  {power: "0.75", axis: "60", cyl: "-0.75"},
  {power: "1", axis: "60", cyl: "-0.75"},
  {power: "1.25", axis: "60", cyl: "-0.75"},
  {power: "1.5", axis: "60", cyl: "-0.75"},
  {power: "1.75", axis: "60", cyl: "-0.75"},
  {power: "2", axis: "60", cyl: "-0.75"},
  {power: "2.25", axis: "60", cyl: "-0.75"},
  {power: "2.5", axis: "60", cyl: "-0.75"},
  {power: "2.75", axis: "60", cyl: "-0.75"},
  {power: "3", axis: "60", cyl: "-0.75"},
  {power: "3.25", axis: "60", cyl: "-0.75"},
  {power: "3.5", axis: "60", cyl: "-0.75"},
  {power: "3.75", axis: "60", cyl: "-0.75"},
  {power: "4", axis: "60", cyl: "-0.75"},
  {power: "4.25", axis: "60", cyl: "-0.75"},
  {power: "4.5", axis: "60", cyl: "-0.75"},
  {power: "4.75", axis: "60", cyl: "-0.75"},
  {power: "5", axis: "60", cyl: "-0.75"},
  {power: "5.25", axis: "60", cyl: "-0.75"},
  {power: "5.5", axis: "60", cyl: "-0.75"},
  {power: "5.75", axis: "60", cyl: "-0.75"},
  {power: "6", axis: "60", cyl: "-0.75"},
  {power: "0.25", axis: "120", cyl: "-0.75"},
  {power: "0.5", axis: "120", cyl: "-0.75"},
  {power: "0.75", axis: "120", cyl: "-0.75"},
  {power: "1", axis: "120", cyl: "-0.75"},
  {power: "1.25", axis: "120", cyl: "-0.75"},
  {power: "1.5", axis: "120", cyl: "-0.75"},
  {power: "1.75", axis: "120", cyl: "-0.75"},
  {power: "2", axis: "120", cyl: "-0.75"},
  {power: "2.25", axis: "120", cyl: "-0.75"},
  {power: "2.5", axis: "120", cyl: "-0.75"},
  {power: "2.75", axis: "120", cyl: "-0.75"},
  {power: "3", axis: "120", cyl: "-0.75"},
  {power: "3.25", axis: "120", cyl: "-0.75"},
  {power: "3.5", axis: "120", cyl: "-0.75"},
  {power: "3.75", axis: "120", cyl: "-0.75"},
  {power: "4", axis: "120", cyl: "-0.75"},
  {power: "4.25", axis: "120", cyl: "-0.75"},
  {power: "4.5", axis: "120", cyl: "-0.75"},
  {power: "4.75", axis: "120", cyl: "-0.75"},
  {power: "5", axis: "120", cyl: "-0.75"},
  {power: "5.25", axis: "120", cyl: "-0.75"},
  {power: "5.5", axis: "120", cyl: "-0.75"},
  {power: "5.75", axis: "120", cyl: "-0.75"},
  {power: "6", axis: "120", cyl: "-0.75"},
  {power: "0.25", axis: "130", cyl: "-0.75"},
  {power: "0.5", axis: "130", cyl: "-0.75"},
  {power: "0.75", axis: "130", cyl: "-0.75"},
  {power: "1", axis: "130", cyl: "-0.75"},
  {power: "1.25", axis: "130", cyl: "-0.75"},
  {power: "1.5", axis: "130", cyl: "-0.75"},
  {power: "1.75", axis: "130", cyl: "-0.75"},
  {power: "2", axis: "130", cyl: "-0.75"},
  {power: "2.25", axis: "130", cyl: "-0.75"},
  {power: "2.5", axis: "130", cyl: "-0.75"},
  {power: "2.75", axis: "130", cyl: "-0.75"},
  {power: "3", axis: "130", cyl: "-0.75"},
  {power: "3.25", axis: "130", cyl: "-0.75"},
  {power: "3.5", axis: "130", cyl: "-0.75"},
  {power: "3.75", axis: "130", cyl: "-0.75"},
  {power: "4", axis: "130", cyl: "-0.75"},
  {power: "4.25", axis: "130", cyl: "-0.75"},
  {power: "4.5", axis: "130", cyl: "-0.75"},
  {power: "4.75", axis: "130", cyl: "-0.75"},
  {power: "5", axis: "130", cyl: "-0.75"},
  {power: "5.25", axis: "130", cyl: "-0.75"},
  {power: "5.5", axis: "130", cyl: "-0.75"},
  {power: "5.75", axis: "130", cyl: "-0.75"},
  {power: "6", axis: "130", cyl: "-0.75"},
  {power: "0.25", axis: "140", cyl: "-0.75"},
  {power: "0.5", axis: "140", cyl: "-0.75"},
  {power: "0.75", axis: "140", cyl: "-0.75"},
  {power: "1", axis: "140", cyl: "-0.75"},
  {power: "1.25", axis: "140", cyl: "-0.75"},
  {power: "1.5", axis: "140", cyl: "-0.75"},
  {power: "1.75", axis: "140", cyl: "-0.75"},
  {power: "2", axis: "140", cyl: "-0.75"},
  {power: "2.25", axis: "140", cyl: "-0.75"},
  {power: "2.5", axis: "140", cyl: "-0.75"},
  {power: "2.75", axis: "140", cyl: "-0.75"},
  {power: "3", axis: "140", cyl: "-0.75"},
  {power: "3.25", axis: "140", cyl: "-0.75"},
  {power: "3.5", axis: "140", cyl: "-0.75"},
  {power: "3.75", axis: "140", cyl: "-0.75"},
  {power: "4", axis: "140", cyl: "-0.75"},
  {power: "4.25", axis: "140", cyl: "-0.75"},
  {power: "4.5", axis: "140", cyl: "-0.75"},
  {power: "4.75", axis: "140", cyl: "-0.75"},
  {power: "5", axis: "140", cyl: "-0.75"},
  {power: "5.25", axis: "140", cyl: "-0.75"},
  {power: "5.5", axis: "140", cyl: "-0.75"},
  {power: "5.75", axis: "140", cyl: "-0.75"},
  {power: "6", axis: "140", cyl: "-0.75"},
  {power: "0.25", axis: "150", cyl: "-0.75"},
  {power: "0.5", axis: "150", cyl: "-0.75"},
  {power: "0.75", axis: "150", cyl: "-0.75"},
  {power: "1", axis: "150", cyl: "-0.75"},
  {power: "1.25", axis: "150", cyl: "-0.75"},
  {power: "1.5", axis: "150", cyl: "-0.75"},
  {power: "1.75", axis: "150", cyl: "-0.75"},
  {power: "2", axis: "150", cyl: "-0.75"},
  {power: "2.25", axis: "150", cyl: "-0.75"},
  {power: "2.5", axis: "150", cyl: "-0.75"},
  {power: "2.75", axis: "150", cyl: "-0.75"},
  {power: "3", axis: "150", cyl: "-0.75"},
  {power: "3.25", axis: "150", cyl: "-0.75"},
  {power: "3.5", axis: "150", cyl: "-0.75"},
  {power: "3.75", axis: "150", cyl: "-0.75"},
  {power: "4", axis: "150", cyl: "-0.75"},
  {power: "4.25", axis: "150", cyl: "-0.75"},
  {power: "4.5", axis: "150", cyl: "-0.75"},
  {power: "4.75", axis: "150", cyl: "-0.75"},
  {power: "5", axis: "150", cyl: "-0.75"},
  {power: "5.25", axis: "150", cyl: "-0.75"},
  {power: "5.5", axis: "150", cyl: "-0.75"},
  {power: "5.75", axis: "150", cyl: "-0.75"},
  {power: "6", axis: "150", cyl: "-0.75"},
  {power: "0.25", axis: "30", cyl: "-1.25"},
  {power: "0.5", axis: "30", cyl: "-1.25"},
  {power: "0.75", axis: "30", cyl: "-1.25"},
  {power: "1", axis: "30", cyl: "-1.25"},
  {power: "1.25", axis: "30", cyl: "-1.25"},
  {power: "1.5", axis: "30", cyl: "-1.25"},
  {power: "1.75", axis: "30", cyl: "-1.25"},
  {power: "2", axis: "30", cyl: "-1.25"},
  {power: "2.25", axis: "30", cyl: "-1.25"},
  {power: "2.5", axis: "30", cyl: "-1.25"},
  {power: "2.75", axis: "30", cyl: "-1.25"},
  {power: "3", axis: "30", cyl: "-1.25"},
  {power: "3.25", axis: "30", cyl: "-1.25"},
  {power: "3.5", axis: "30", cyl: "-1.25"},
  {power: "3.75", axis: "30", cyl: "-1.25"},
  {power: "4", axis: "30", cyl: "-1.25"},
  {power: "4.25", axis: "30", cyl: "-1.25"},
  {power: "4.5", axis: "30", cyl: "-1.25"},
  {power: "4.75", axis: "30", cyl: "-1.25"},
  {power: "5", axis: "30", cyl: "-1.25"},
  {power: "5.25", axis: "30", cyl: "-1.25"},
  {power: "5.5", axis: "30", cyl: "-1.25"},
  {power: "5.75", axis: "30", cyl: "-1.25"},
  {power: "6", axis: "30", cyl: "-1.25"},
  {power: "0.25", axis: "40", cyl: "-1.25"},
  {power: "0.5", axis: "40", cyl: "-1.25"},
  {power: "0.75", axis: "40", cyl: "-1.25"},
  {power: "1", axis: "40", cyl: "-1.25"},
  {power: "1.25", axis: "40", cyl: "-1.25"},
  {power: "1.5", axis: "40", cyl: "-1.25"},
  {power: "1.75", axis: "40", cyl: "-1.25"},
  {power: "2", axis: "40", cyl: "-1.25"},
  {power: "2.25", axis: "40", cyl: "-1.25"},
  {power: "2.5", axis: "40", cyl: "-1.25"},
  {power: "2.75", axis: "40", cyl: "-1.25"},
  {power: "3", axis: "40", cyl: "-1.25"},
  {power: "3.25", axis: "40", cyl: "-1.25"},
  {power: "3.5", axis: "40", cyl: "-1.25"},
  {power: "3.75", axis: "40", cyl: "-1.25"},
  {power: "4", axis: "40", cyl: "-1.25"},
  {power: "4.25", axis: "40", cyl: "-1.25"},
  {power: "4.5", axis: "40", cyl: "-1.25"},
  {power: "4.75", axis: "40", cyl: "-1.25"},
  {power: "5", axis: "40", cyl: "-1.25"},
  {power: "5.25", axis: "40", cyl: "-1.25"},
  {power: "5.5", axis: "40", cyl: "-1.25"},
  {power: "5.75", axis: "40", cyl: "-1.25"},
  {power: "6", axis: "40", cyl: "-1.25"},
  {power: "0.25", axis: "50", cyl: "-1.25"},
  {power: "0.75", axis: "50", cyl: "-1.25"},
  {power: "1", axis: "50", cyl: "-1.25"},
  {power: "1.25", axis: "50", cyl: "-1.25"},
  {power: "1.5", axis: "50", cyl: "-1.25"},
  {power: "1.75", axis: "50", cyl: "-1.25"},
  {power: "2", axis: "50", cyl: "-1.25"},
  {power: "2.25", axis: "50", cyl: "-1.25"},
  {power: "2.5", axis: "50", cyl: "-1.25"},
  {power: "2.75", axis: "50", cyl: "-1.25"},
  {power: "3", axis: "50", cyl: "-1.25"},
  {power: "3.25", axis: "50", cyl: "-1.25"},
  {power: "3.5", axis: "50", cyl: "-1.25"},
  {power: "3.75", axis: "50", cyl: "-1.25"},
  {power: "4", axis: "50", cyl: "-1.25"},
  {power: "4.25", axis: "50", cyl: "-1.25"},
  {power: "4.5", axis: "50", cyl: "-1.25"},
  {power: "4.75", axis: "50", cyl: "-1.25"},
  {power: "5", axis: "50", cyl: "-1.25"},
  {power: "5.25", axis: "50", cyl: "-1.25"},
  {power: "5.5", axis: "50", cyl: "-1.25"},
  {power: "5.75", axis: "50", cyl: "-1.25"},
  {power: "6", axis: "50", cyl: "-1.25"},
  {power: "0.25", axis: "60", cyl: "-1.25"},
  {power: "0.5", axis: "60", cyl: "-1.25"},
  {power: "0.75", axis: "60", cyl: "-1.25"},
  {power: "1", axis: "60", cyl: "-1.25"},
  {power: "1.25", axis: "60", cyl: "-1.25"},
  {power: "1.5", axis: "60", cyl: "-1.25"},
  {power: "1.75", axis: "60", cyl: "-1.25"},
  {power: "2", axis: "60", cyl: "-1.25"},
  {power: "2.25", axis: "60", cyl: "-1.25"},
  {power: "2.5", axis: "60", cyl: "-1.25"},
  {power: "2.75", axis: "60", cyl: "-1.25"},
  {power: "3", axis: "60", cyl: "-1.25"},
  {power: "3.25", axis: "60", cyl: "-1.25"},
  {power: "3.5", axis: "60", cyl: "-1.25"},
  {power: "3.75", axis: "60", cyl: "-1.25"},
  {power: "4", axis: "60", cyl: "-1.25"},
  {power: "4.25", axis: "60", cyl: "-1.25"},
  {power: "4.5", axis: "60", cyl: "-1.25"},
  {power: "4.75", axis: "60", cyl: "-1.25"},
  {power: "5", axis: "60", cyl: "-1.25"},
  {power: "5.25", axis: "60", cyl: "-1.25"},
  {power: "5.5", axis: "60", cyl: "-1.25"},
  {power: "5.75", axis: "60", cyl: "-1.25"},
  {power: "6", axis: "60", cyl: "-1.25"},
  {power: "0.25", axis: "120", cyl: "-1.25"},
  {power: "0.5", axis: "120", cyl: "-1.25"},
  {power: "0.75", axis: "120", cyl: "-1.25"},
  {power: "1", axis: "120", cyl: "-1.25"},
  {power: "1.25", axis: "120", cyl: "-1.25"},
  {power: "1.5", axis: "120", cyl: "-1.25"},
  {power: "1.75", axis: "120", cyl: "-1.25"},
  {power: "2", axis: "120", cyl: "-1.25"},
  {power: "2.25", axis: "120", cyl: "-1.25"},
  {power: "2.5", axis: "120", cyl: "-1.25"},
  {power: "2.75", axis: "120", cyl: "-1.25"},
  {power: "3", axis: "120", cyl: "-1.25"},
  {power: "3.25", axis: "120", cyl: "-1.25"},
  {power: "3.5", axis: "120", cyl: "-1.25"},
  {power: "3.75", axis: "120", cyl: "-1.25"},
  {power: "4", axis: "120", cyl: "-1.25"},
  {power: "4.25", axis: "120", cyl: "-1.25"},
  {power: "4.5", axis: "120", cyl: "-1.25"},
  {power: "4.75", axis: "120", cyl: "-1.25"},
  {power: "5", axis: "120", cyl: "-1.25"},
  {power: "5.25", axis: "120", cyl: "-1.25"},
  {power: "5.5", axis: "120", cyl: "-1.25"},
  {power: "5.75", axis: "120", cyl: "-1.25"},
  {power: "6", axis: "120", cyl: "-1.25"},
  {power: "0.25", axis: "130", cyl: "-1.25"},
  {power: "0.5", axis: "130", cyl: "-1.25"},
  {power: "0.75", axis: "130", cyl: "-1.25"},
  {power: "1", axis: "130", cyl: "-1.25"},
  {power: "1.25", axis: "130", cyl: "-1.25"},
  {power: "1.5", axis: "130", cyl: "-1.25"},
  {power: "1.75", axis: "130", cyl: "-1.25"},
  {power: "2", axis: "130", cyl: "-1.25"},
  {power: "2.25", axis: "130", cyl: "-1.25"},
  {power: "2.5", axis: "130", cyl: "-1.25"},
  {power: "2.75", axis: "130", cyl: "-1.25"},
  {power: "3", axis: "130", cyl: "-1.25"},
  {power: "3.25", axis: "130", cyl: "-1.25"},
  {power: "3.5", axis: "130", cyl: "-1.25"},
  {power: "3.75", axis: "130", cyl: "-1.25"},
  {power: "4", axis: "130", cyl: "-1.25"},
  {power: "4.25", axis: "130", cyl: "-1.25"},
  {power: "4.5", axis: "130", cyl: "-1.25"},
  {power: "4.75", axis: "130", cyl: "-1.25"},
  {power: "5", axis: "130", cyl: "-1.25"},
  {power: "5.25", axis: "130", cyl: "-1.25"},
  {power: "5.5", axis: "130", cyl: "-1.25"},
  {power: "5.75", axis: "130", cyl: "-1.25"},
  {power: "6", axis: "130", cyl: "-1.25"},
  {power: "0.25", axis: "140", cyl: "-1.25"},
  {power: "0.5", axis: "140", cyl: "-1.25"},
  {power: "0.75", axis: "140", cyl: "-1.25"},
  {power: "1", axis: "140", cyl: "-1.25"},
  {power: "1.25", axis: "140", cyl: "-1.25"},
  {power: "1.5", axis: "140", cyl: "-1.25"},
  {power: "1.75", axis: "140", cyl: "-1.25"},
  {power: "2", axis: "140", cyl: "-1.25"},
  {power: "2.25", axis: "140", cyl: "-1.25"},
  {power: "2.5", axis: "140", cyl: "-1.25"},
  {power: "2.75", axis: "140", cyl: "-1.25"},
  {power: "3", axis: "140", cyl: "-1.25"},
  {power: "3.25", axis: "140", cyl: "-1.25"},
  {power: "3.5", axis: "140", cyl: "-1.25"},
  {power: "3.75", axis: "140", cyl: "-1.25"},
  {power: "4", axis: "140", cyl: "-1.25"},
  {power: "4.25", axis: "140", cyl: "-1.25"},
  {power: "4.5", axis: "140", cyl: "-1.25"},
  {power: "4.75", axis: "140", cyl: "-1.25"},
  {power: "5", axis: "140", cyl: "-1.25"},
  {power: "5.25", axis: "140", cyl: "-1.25"},
  {power: "5.5", axis: "140", cyl: "-1.25"},
  {power: "5.75", axis: "140", cyl: "-1.25"},
  {power: "6", axis: "140", cyl: "-1.25"},
  {power: "0.25", axis: "150", cyl: "-1.25"},
  {power: "0.5", axis: "150", cyl: "-1.25"},
  {power: "0.75", axis: "150", cyl: "-1.25"},
  {power: "1", axis: "150", cyl: "-1.25"},
  {power: "1.25", axis: "150", cyl: "-1.25"},
  {power: "1.5", axis: "150", cyl: "-1.25"},
  {power: "1.75", axis: "150", cyl: "-1.25"},
  {power: "2", axis: "150", cyl: "-1.25"},
  {power: "2.25", axis: "150", cyl: "-1.25"},
  {power: "2.5", axis: "150", cyl: "-1.25"},
  {power: "2.75", axis: "150", cyl: "-1.25"},
  {power: "3", axis: "150", cyl: "-1.25"},
  {power: "3.25", axis: "150", cyl: "-1.25"},
  {power: "3.5", axis: "150", cyl: "-1.25"},
  {power: "3.75", axis: "150", cyl: "-1.25"},
  {power: "4", axis: "150", cyl: "-1.25"},
  {power: "4.25", axis: "150", cyl: "-1.25"},
  {power: "4.5", axis: "150", cyl: "-1.25"},
  {power: "4.75", axis: "150", cyl: "-1.25"},
  {power: "5", axis: "150", cyl: "-1.25"},
  {power: "5.25", axis: "150", cyl: "-1.25"},
  {power: "5.5", axis: "150", cyl: "-1.25"},
  {power: "5.75", axis: "150", cyl: "-1.25"},
  {power: "6", axis: "150", cyl: "-1.25"},
  {power: "-6.5", axis: "30", cyl: "-1.75"},
  {power: "-7", axis: "30", cyl: "-1.75"},
  {power: "-7.5", axis: "30", cyl: "-1.75"},
  {power: "-8", axis: "30", cyl: "-1.75"},
  {power: "-8.5", axis: "30", cyl: "-1.75"},
  {power: "-9", axis: "30", cyl: "-1.75"},
  {power: "0.25", axis: "30", cyl: "-1.75"},
  {power: "0.5", axis: "30", cyl: "-1.75"},
  {power: "0.75", axis: "30", cyl: "-1.75"},
  {power: "1", axis: "30", cyl: "-1.75"},
  {power: "1.25", axis: "30", cyl: "-1.75"},
  {power: "1.5", axis: "30", cyl: "-1.75"},
  {power: "1.75", axis: "30", cyl: "-1.75"},
  {power: "2", axis: "30", cyl: "-1.75"},
  {power: "2.25", axis: "30", cyl: "-1.75"},
  {power: "2.5", axis: "30", cyl: "-1.75"},
  {power: "2.75", axis: "30", cyl: "-1.75"},
  {power: "3", axis: "30", cyl: "-1.75"},
  {power: "3.25", axis: "30", cyl: "-1.75"},
  {power: "3.5", axis: "30", cyl: "-1.75"},
  {power: "3.75", axis: "30", cyl: "-1.75"},
  {power: "4", axis: "30", cyl: "-1.75"},
  {power: "4.25", axis: "30", cyl: "-1.75"},
  {power: "4.5", axis: "30", cyl: "-1.75"},
  {power: "4.75", axis: "30", cyl: "-1.75"},
  {power: "5", axis: "30", cyl: "-1.75"},
  {power: "5.25", axis: "30", cyl: "-1.75"},
  {power: "5.5", axis: "30", cyl: "-1.75"},
  {power: "5.75", axis: "30", cyl: "-1.75"},
  {power: "6", axis: "30", cyl: "-1.75"},
  {power: "-6.5", axis: "40", cyl: "-1.75"},
  {power: "-7", axis: "40", cyl: "-1.75"},
  {power: "-7.5", axis: "40", cyl: "-1.75"},
  {power: "-8", axis: "40", cyl: "-1.75"},
  {power: "-8.5", axis: "40", cyl: "-1.75"},
  {power: "-9", axis: "40", cyl: "-1.75"},
  {power: "0.25", axis: "40", cyl: "-1.75"},
  {power: "0.5", axis: "40", cyl: "-1.75"},
  {power: "0.75", axis: "40", cyl: "-1.75"},
  {power: "1", axis: "40", cyl: "-1.75"},
  {power: "1.25", axis: "40", cyl: "-1.75"},
  {power: "1.5", axis: "40", cyl: "-1.75"},
  {power: "1.75", axis: "40", cyl: "-1.75"},
  {power: "2", axis: "40", cyl: "-1.75"},
  {power: "2.25", axis: "40", cyl: "-1.75"},
  {power: "2.5", axis: "40", cyl: "-1.75"},
  {power: "2.75", axis: "40", cyl: "-1.75"},
  {power: "3", axis: "40", cyl: "-1.75"},
  {power: "3.25", axis: "40", cyl: "-1.75"},
  {power: "3.5", axis: "40", cyl: "-1.75"},
  {power: "3.75", axis: "40", cyl: "-1.75"},
  {power: "4", axis: "40", cyl: "-1.75"},
  {power: "4.25", axis: "40", cyl: "-1.75"},
  {power: "4.5", axis: "40", cyl: "-1.75"},
  {power: "4.75", axis: "40", cyl: "-1.75"},
  {power: "5", axis: "40", cyl: "-1.75"},
  {power: "5.25", axis: "40", cyl: "-1.75"},
  {power: "5.5", axis: "40", cyl: "-1.75"},
  {power: "5.75", axis: "40", cyl: "-1.75"},
  {power: "6", axis: "40", cyl: "-1.75"},
  {power: "-6.5", axis: "50", cyl: "-1.75"},
  {power: "-7", axis: "50", cyl: "-1.75"},
  {power: "-7.5", axis: "50", cyl: "-1.75"},
  {power: "-8", axis: "50", cyl: "-1.75"},
  {power: "-8.5", axis: "50", cyl: "-1.75"},
  {power: "-9", axis: "50", cyl: "-1.75"},
  {power: "0.25", axis: "50", cyl: "-1.75"},
  {power: "0.5", axis: "50", cyl: "-1.75"},
  {power: "0.75", axis: "50", cyl: "-1.75"},
  {power: "1", axis: "50", cyl: "-1.75"},
  {power: "1.25", axis: "50", cyl: "-1.75"},
  {power: "1.5", axis: "50", cyl: "-1.75"},
  {power: "1.75", axis: "50", cyl: "-1.75"},
  {power: "2", axis: "50", cyl: "-1.75"},
  {power: "2.25", axis: "50", cyl: "-1.75"},
  {power: "2.5", axis: "50", cyl: "-1.75"},
  {power: "2.75", axis: "50", cyl: "-1.75"},
  {power: "3", axis: "50", cyl: "-1.75"},
  {power: "3.25", axis: "50", cyl: "-1.75"},
  {power: "3.5", axis: "50", cyl: "-1.75"},
  {power: "3.75", axis: "50", cyl: "-1.75"},
  {power: "4", axis: "50", cyl: "-1.75"},
  {power: "4.25", axis: "50", cyl: "-1.75"},
  {power: "4.5", axis: "50", cyl: "-1.75"},
  {power: "4.75", axis: "50", cyl: "-1.75"},
  {power: "5", axis: "50", cyl: "-1.75"},
  {power: "5.25", axis: "50", cyl: "-1.75"},
  {power: "5.5", axis: "50", cyl: "-1.75"},
  {power: "5.75", axis: "50", cyl: "-1.75"},
  {power: "6", axis: "50", cyl: "-1.75"},
  {power: "-6.5", axis: "60", cyl: "-1.75"},
  {power: "-7", axis: "60", cyl: "-1.75"},
  {power: "-7.5", axis: "60", cyl: "-1.75"},
  {power: "-8", axis: "60", cyl: "-1.75"},
  {power: "-8.5", axis: "60", cyl: "-1.75"},
  {power: "-9", axis: "60", cyl: "-1.75"},
  {power: "0.25", axis: "60", cyl: "-1.75"},
  {power: "0.5", axis: "60", cyl: "-1.75"},
  {power: "0.75", axis: "60", cyl: "-1.75"},
  {power: "1", axis: "60", cyl: "-1.75"},
  {power: "1.25", axis: "60", cyl: "-1.75"},
  {power: "1.5", axis: "60", cyl: "-1.75"},
  {power: "1.75", axis: "60", cyl: "-1.75"},
  {power: "2", axis: "60", cyl: "-1.75"},
  {power: "2.25", axis: "60", cyl: "-1.75"},
  {power: "2.5", axis: "60", cyl: "-1.75"},
  {power: "2.75", axis: "60", cyl: "-1.75"},
  {power: "3", axis: "60", cyl: "-1.75"},
  {power: "3.25", axis: "60", cyl: "-1.75"},
  {power: "3.5", axis: "60", cyl: "-1.75"},
  {power: "3.75", axis: "60", cyl: "-1.75"},
  {power: "4", axis: "60", cyl: "-1.75"},
  {power: "4.25", axis: "60", cyl: "-1.75"},
  {power: "4.5", axis: "60", cyl: "-1.75"},
  {power: "4.75", axis: "60", cyl: "-1.75"},
  {power: "5", axis: "60", cyl: "-1.75"},
  {power: "5.25", axis: "60", cyl: "-1.75"},
  {power: "5.5", axis: "60", cyl: "-1.75"},
  {power: "5.75", axis: "60", cyl: "-1.75"},
  {power: "6", axis: "60", cyl: "-1.75"},
  {power: "-6.5", axis: "70", cyl: "-1.75"},
  {power: "-7", axis: "70", cyl: "-1.75"},
  {power: "-7.5", axis: "70", cyl: "-1.75"},
  {power: "-8", axis: "70", cyl: "-1.75"},
  {power: "-8.5", axis: "70", cyl: "-1.75"},
  {power: "-9", axis: "70", cyl: "-1.75"},
  {power: "-6.5", axis: "80", cyl: "-1.75"},
  {power: "-7", axis: "80", cyl: "-1.75"},
  {power: "-7.5", axis: "80", cyl: "-1.75"},
  {power: "-8", axis: "80", cyl: "-1.75"},
  {power: "-8.5", axis: "80", cyl: "-1.75"},
  {power: "-9", axis: "80", cyl: "-1.75"},
  {power: "-6.5", axis: "90", cyl: "-1.75"},
  {power: "-7", axis: "90", cyl: "-1.75"},
  {power: "-7.5", axis: "90", cyl: "-1.75"},
  {power: "-8", axis: "90", cyl: "-1.75"},
  {power: "-8.5", axis: "90", cyl: "-1.75"},
  {power: "-9", axis: "90", cyl: "-1.75"},
  {power: "-6.5", axis: "100", cyl: "-1.75"},
  {power: "-7", axis: "100", cyl: "-1.75"},
  {power: "-7.5", axis: "100", cyl: "-1.75"},
  {power: "-8", axis: "100", cyl: "-1.75"},
  {power: "-8.5", axis: "100", cyl: "-1.75"},
  {power: "-9", axis: "100", cyl: "-1.75"},
  {power: "-6.5", axis: "110", cyl: "-1.75"},
  {power: "-7", axis: "110", cyl: "-1.75"},
  {power: "-7.5", axis: "110", cyl: "-1.75"},
  {power: "-8", axis: "110", cyl: "-1.75"},
  {power: "-8.5", axis: "110", cyl: "-1.75"},
  {power: "-9", axis: "110", cyl: "-1.75"},
  {power: "-6.5", axis: "120", cyl: "-1.75"},
  {power: "-7", axis: "120", cyl: "-1.75"},
  {power: "-7.5", axis: "120", cyl: "-1.75"},
  {power: "-8", axis: "120", cyl: "-1.75"},
  {power: "-8.5", axis: "120", cyl: "-1.75"},
  {power: "-9", axis: "120", cyl: "-1.75"},
  {power: "0.25", axis: "120", cyl: "-1.75"},
  {power: "0.5", axis: "120", cyl: "-1.75"},
  {power: "0.75", axis: "120", cyl: "-1.75"},
  {power: "1", axis: "120", cyl: "-1.75"},
  {power: "1.25", axis: "120", cyl: "-1.75"},
  {power: "1.5", axis: "120", cyl: "-1.75"},
  {power: "1.75", axis: "120", cyl: "-1.75"},
  {power: "2", axis: "120", cyl: "-1.75"},
  {power: "2.25", axis: "120", cyl: "-1.75"},
  {power: "2.5", axis: "120", cyl: "-1.75"},
  {power: "2.75", axis: "120", cyl: "-1.75"},
  {power: "3", axis: "120", cyl: "-1.75"},
  {power: "3.25", axis: "120", cyl: "-1.75"},
  {power: "3.5", axis: "120", cyl: "-1.75"},
  {power: "3.75", axis: "120", cyl: "-1.75"},
  {power: "4", axis: "120", cyl: "-1.75"},
  {power: "4.25", axis: "120", cyl: "-1.75"},
  {power: "4.5", axis: "120", cyl: "-1.75"},
  {power: "4.75", axis: "120", cyl: "-1.75"},
  {power: "5", axis: "120", cyl: "-1.75"},
  {power: "5.25", axis: "120", cyl: "-1.75"},
  {power: "5.5", axis: "120", cyl: "-1.75"},
  {power: "5.75", axis: "120", cyl: "-1.75"},
  {power: "6", axis: "120", cyl: "-1.75"},
  {power: "-6.5", axis: "130", cyl: "-1.75"},
  {power: "-7", axis: "130", cyl: "-1.75"},
  {power: "-7.5", axis: "130", cyl: "-1.75"},
  {power: "-8", axis: "130", cyl: "-1.75"},
  {power: "-8.5", axis: "130", cyl: "-1.75"},
  {power: "-9", axis: "130", cyl: "-1.75"},
  {power: "0.25", axis: "130", cyl: "-1.75"},
  {power: "0.5", axis: "130", cyl: "-1.75"},
  {power: "0.75", axis: "130", cyl: "-1.75"},
  {power: "1", axis: "130", cyl: "-1.75"},
  {power: "1.25", axis: "130", cyl: "-1.75"},
  {power: "1.5", axis: "130", cyl: "-1.75"},
  {power: "1.75", axis: "130", cyl: "-1.75"},
  {power: "2", axis: "130", cyl: "-1.75"},
  {power: "2.25", axis: "130", cyl: "-1.75"},
  {power: "2.5", axis: "130", cyl: "-1.75"},
  {power: "2.75", axis: "130", cyl: "-1.75"},
  {power: "3", axis: "130", cyl: "-1.75"},
  {power: "3.25", axis: "130", cyl: "-1.75"},
  {power: "3.5", axis: "130", cyl: "-1.75"},
  {power: "3.75", axis: "130", cyl: "-1.75"},
  {power: "4", axis: "130", cyl: "-1.75"},
  {power: "4.25", axis: "130", cyl: "-1.75"},
  {power: "4.5", axis: "130", cyl: "-1.75"},
  {power: "4.75", axis: "130", cyl: "-1.75"},
  {power: "5", axis: "130", cyl: "-1.75"},
  {power: "5.25", axis: "130", cyl: "-1.75"},
  {power: "5.5", axis: "130", cyl: "-1.75"},
  {power: "5.75", axis: "130", cyl: "-1.75"},
  {power: "6", axis: "130", cyl: "-1.75"},
  {power: "-6.5", axis: "140", cyl: "-1.75"},
  {power: "-7", axis: "140", cyl: "-1.75"},
  {power: "-7.5", axis: "140", cyl: "-1.75"},
  {power: "-8", axis: "140", cyl: "-1.75"},
  {power: "-8.5", axis: "140", cyl: "-1.75"},
  {power: "-9", axis: "140", cyl: "-1.75"},
  {power: "0.25", axis: "140", cyl: "-1.75"},
  {power: "0.5", axis: "140", cyl: "-1.75"},
  {power: "0.75", axis: "140", cyl: "-1.75"},
  {power: "1", axis: "140", cyl: "-1.75"},
  {power: "1.25", axis: "140", cyl: "-1.75"},
  {power: "1.5", axis: "140", cyl: "-1.75"},
  {power: "1.75", axis: "140", cyl: "-1.75"},
  {power: "2", axis: "140", cyl: "-1.75"},
  {power: "2.25", axis: "140", cyl: "-1.75"},
  {power: "2.5", axis: "140", cyl: "-1.75"},
  {power: "2.75", axis: "140", cyl: "-1.75"},
  {power: "3", axis: "140", cyl: "-1.75"},
  {power: "3.25", axis: "140", cyl: "-1.75"},
  {power: "3.5", axis: "140", cyl: "-1.75"},
  {power: "3.75", axis: "140", cyl: "-1.75"},
  {power: "4", axis: "140", cyl: "-1.75"},
  {power: "4.25", axis: "140", cyl: "-1.75"},
  {power: "4.5", axis: "140", cyl: "-1.75"},
  {power: "4.75", axis: "140", cyl: "-1.75"},
  {power: "5", axis: "140", cyl: "-1.75"},
  {power: "5.25", axis: "140", cyl: "-1.75"},
  {power: "5.5", axis: "140", cyl: "-1.75"},
  {power: "5.75", axis: "140", cyl: "-1.75"},
  {power: "6", axis: "140", cyl: "-1.75"},
  {power: "-6.5", axis: "150", cyl: "-1.75"},
  {power: "-7", axis: "150", cyl: "-1.75"},
  {power: "-7.5", axis: "150", cyl: "-1.75"},
  {power: "-8", axis: "150", cyl: "-1.75"},
  {power: "-8.5", axis: "150", cyl: "-1.75"},
  {power: "-9", axis: "150", cyl: "-1.75"},
  {power: "0.25", axis: "150", cyl: "-1.75"},
  {power: "0.5", axis: "150", cyl: "-1.75"},
  {power: "0.75", axis: "150", cyl: "-1.75"},
  {power: "1", axis: "150", cyl: "-1.75"},
  {power: "1.25", axis: "150", cyl: "-1.75"},
  {power: "1.5", axis: "150", cyl: "-1.75"},
  {power: "1.75", axis: "150", cyl: "-1.75"},
  {power: "2", axis: "150", cyl: "-1.75"},
  {power: "2.25", axis: "150", cyl: "-1.75"},
  {power: "2.5", axis: "150", cyl: "-1.75"},
  {power: "2.75", axis: "150", cyl: "-1.75"},
  {power: "3", axis: "150", cyl: "-1.75"},
  {power: "3.25", axis: "150", cyl: "-1.75"},
  {power: "3.5", axis: "150", cyl: "-1.75"},
  {power: "3.75", axis: "150", cyl: "-1.75"},
  {power: "4", axis: "150", cyl: "-1.75"},
  {power: "4.25", axis: "150", cyl: "-1.75"},
  {power: "4.5", axis: "150", cyl: "-1.75"},
  {power: "4.75", axis: "150", cyl: "-1.75"},
  {power: "5", axis: "150", cyl: "-1.75"},
  {power: "5.25", axis: "150", cyl: "-1.75"},
  {power: "5.5", axis: "150", cyl: "-1.75"},
  {power: "5.75", axis: "150", cyl: "-1.75"},
  {power: "6", axis: "150", cyl: "-1.75"},
  {power: "0.25", axis: "10", cyl: "-2.25"},
  {power: "0.5", axis: "10", cyl: "-2.25"},
  {power: "0.75", axis: "10", cyl: "-2.25"},
  {power: "1", axis: "10", cyl: "-2.25"},
  {power: "1.25", axis: "10", cyl: "-2.25"},
  {power: "1.5", axis: "10", cyl: "-2.25"},
  {power: "1.75", axis: "10", cyl: "-2.25"},
  {power: "2", axis: "10", cyl: "-2.25"},
  {power: "2.25", axis: "10", cyl: "-2.25"},
  {power: "2.5", axis: "10", cyl: "-2.25"},
  {power: "2.75", axis: "10", cyl: "-2.25"},
  {power: "3", axis: "10", cyl: "-2.25"},
  {power: "3.25", axis: "10", cyl: "-2.25"},
  {power: "3.5", axis: "10", cyl: "-2.25"},
  {power: "3.75", axis: "10", cyl: "-2.25"},
  {power: "4", axis: "10", cyl: "-2.25"},
  {power: "4.25", axis: "10", cyl: "-2.25"},
  {power: "4.5", axis: "10", cyl: "-2.25"},
  {power: "4.75", axis: "10", cyl: "-2.25"},
  {power: "5", axis: "10", cyl: "-2.25"},
  {power: "5.25", axis: "10", cyl: "-2.25"},
  {power: "5.5", axis: "10", cyl: "-2.25"},
  {power: "5.75", axis: "10", cyl: "-2.25"},
  {power: "6", axis: "10", cyl: "-2.25"},
  {power: "0.25", axis: "20", cyl: "-2.25"},
  {power: "0.5", axis: "20", cyl: "-2.25"},
  {power: "0.75", axis: "20", cyl: "-2.25"},
  {power: "1", axis: "20", cyl: "-2.25"},
  {power: "1.25", axis: "20", cyl: "-2.25"},
  {power: "1.5", axis: "20", cyl: "-2.25"},
  {power: "1.75", axis: "20", cyl: "-2.25"},
  {power: "2", axis: "20", cyl: "-2.25"},
  {power: "2.25", axis: "20", cyl: "-2.25"},
  {power: "2.5", axis: "20", cyl: "-2.25"},
  {power: "2.75", axis: "20", cyl: "-2.25"},
  {power: "3", axis: "20", cyl: "-2.25"},
  {power: "3.25", axis: "20", cyl: "-2.25"},
  {power: "3.5", axis: "20", cyl: "-2.25"},
  {power: "3.75", axis: "20", cyl: "-2.25"},
  {power: "4", axis: "20", cyl: "-2.25"},
  {power: "4.25", axis: "20", cyl: "-2.25"},
  {power: "4.5", axis: "20", cyl: "-2.25"},
  {power: "4.75", axis: "20", cyl: "-2.25"},
  {power: "5", axis: "20", cyl: "-2.25"},
  {power: "5.25", axis: "20", cyl: "-2.25"},
  {power: "5.5", axis: "20", cyl: "-2.25"},
  {power: "5.75", axis: "20", cyl: "-2.25"},
  {power: "6", axis: "20", cyl: "-2.25"},
  {power: "0", axis: "30", cyl: "-2.25"},
  {power: "-0.25", axis: "30", cyl: "-2.25"},
  {power: "-0.5", axis: "30", cyl: "-2.25"},
  {power: "-0.75", axis: "30", cyl: "-2.25"},
  {power: "-1", axis: "30", cyl: "-2.25"},
  {power: "-1.25", axis: "30", cyl: "-2.25"},
  {power: "-1.5", axis: "30", cyl: "-2.25"},
  {power: "-1.75", axis: "30", cyl: "-2.25"},
  {power: "-2", axis: "30", cyl: "-2.25"},
  {power: "-2.25", axis: "30", cyl: "-2.25"},
  {power: "-2.5", axis: "30", cyl: "-2.25"},
  {power: "-2.75", axis: "30", cyl: "-2.25"},
  {power: "-3", axis: "30", cyl: "-2.25"},
  {power: "-3.25", axis: "30", cyl: "-2.25"},
  {power: "-3.5", axis: "30", cyl: "-2.25"},
  {power: "-3.75", axis: "30", cyl: "-2.25"},
  {power: "-4", axis: "30", cyl: "-2.25"},
  {power: "-4.25", axis: "30", cyl: "-2.25"},
  {power: "-4.5", axis: "30", cyl: "-2.25"},
  {power: "-4.75", axis: "30", cyl: "-2.25"},
  {power: "-5", axis: "30", cyl: "-2.25"},
  {power: "-5.25", axis: "30", cyl: "-2.25"},
  {power: "-5.5", axis: "30", cyl: "-2.25"},
  {power: "-5.75", axis: "30", cyl: "-2.25"},
  {power: "-6", axis: "30", cyl: "-2.25"},
  {power: "-6.5", axis: "30", cyl: "-2.25"},
  {power: "-7", axis: "30", cyl: "-2.25"},
  {power: "-7.5", axis: "30", cyl: "-2.25"},
  {power: "-8", axis: "30", cyl: "-2.25"},
  {power: "-8.5", axis: "30", cyl: "-2.25"},
  {power: "-9", axis: "30", cyl: "-2.25"},
  {power: "0.25", axis: "30", cyl: "-2.25"},
  {power: "0.5", axis: "30", cyl: "-2.25"},
  {power: "0.75", axis: "30", cyl: "-2.25"},
  {power: "1", axis: "30", cyl: "-2.25"},
  {power: "1.25", axis: "30", cyl: "-2.25"},
  {power: "1.5", axis: "30", cyl: "-2.25"},
  {power: "1.75", axis: "30", cyl: "-2.25"},
  {power: "2", axis: "30", cyl: "-2.25"},
  {power: "2.25", axis: "30", cyl: "-2.25"},
  {power: "2.5", axis: "30", cyl: "-2.25"},
  {power: "2.75", axis: "30", cyl: "-2.25"},
  {power: "3", axis: "30", cyl: "-2.25"},
  {power: "3.25", axis: "30", cyl: "-2.25"},
  {power: "3.5", axis: "30", cyl: "-2.25"},
  {power: "3.75", axis: "30", cyl: "-2.25"},
  {power: "4", axis: "30", cyl: "-2.25"},
  {power: "4.25", axis: "30", cyl: "-2.25"},
  {power: "4.5", axis: "30", cyl: "-2.25"},
  {power: "4.75", axis: "30", cyl: "-2.25"},
  {power: "5", axis: "30", cyl: "-2.25"},
  {power: "5.25", axis: "30", cyl: "-2.25"},
  {power: "5.5", axis: "30", cyl: "-2.25"},
  {power: "5.75", axis: "30", cyl: "-2.25"},
  {power: "6", axis: "30", cyl: "-2.25"},
  {power: "0", axis: "40", cyl: "-2.25"},
  {power: "-0.25", axis: "40", cyl: "-2.25"},
  {power: "-0.5", axis: "40", cyl: "-2.25"},
  {power: "-0.75", axis: "40", cyl: "-2.25"},
  {power: "-1", axis: "40", cyl: "-2.25"},
  {power: "-1.25", axis: "40", cyl: "-2.25"},
  {power: "-1.5", axis: "40", cyl: "-2.25"},
  {power: "-1.75", axis: "40", cyl: "-2.25"},
  {power: "-2", axis: "40", cyl: "-2.25"},
  {power: "-2.25", axis: "40", cyl: "-2.25"},
  {power: "-2.5", axis: "40", cyl: "-2.25"},
  {power: "-2.75", axis: "40", cyl: "-2.25"},
  {power: "-3", axis: "40", cyl: "-2.25"},
  {power: "-3.25", axis: "40", cyl: "-2.25"},
  {power: "-3.5", axis: "40", cyl: "-2.25"},
  {power: "-3.75", axis: "40", cyl: "-2.25"},
  {power: "-4", axis: "40", cyl: "-2.25"},
  {power: "-4.25", axis: "40", cyl: "-2.25"},
  {power: "-4.5", axis: "40", cyl: "-2.25"},
  {power: "-4.75", axis: "40", cyl: "-2.25"},
  {power: "-5", axis: "40", cyl: "-2.25"},
  {power: "-5.25", axis: "40", cyl: "-2.25"},
  {power: "-5.5", axis: "40", cyl: "-2.25"},
  {power: "-5.75", axis: "40", cyl: "-2.25"},
  {power: "-6", axis: "40", cyl: "-2.25"},
  {power: "-6.5", axis: "40", cyl: "-2.25"},
  {power: "-7", axis: "40", cyl: "-2.25"},
  {power: "-7.5", axis: "40", cyl: "-2.25"},
  {power: "-8", axis: "40", cyl: "-2.25"},
  {power: "-8.5", axis: "40", cyl: "-2.25"},
  {power: "-9", axis: "40", cyl: "-2.25"},
  {power: "0.25", axis: "40", cyl: "-2.25"},
  {power: "0.5", axis: "40", cyl: "-2.25"},
  {power: "0.75", axis: "40", cyl: "-2.25"},
  {power: "1", axis: "40", cyl: "-2.25"},
  {power: "1.25", axis: "40", cyl: "-2.25"},
  {power: "1.5", axis: "40", cyl: "-2.25"},
  {power: "1.75", axis: "40", cyl: "-2.25"},
  {power: "2", axis: "40", cyl: "-2.25"},
  {power: "2.25", axis: "40", cyl: "-2.25"},
  {power: "2.5", axis: "40", cyl: "-2.25"},
  {power: "2.75", axis: "40", cyl: "-2.25"},
  {power: "3", axis: "40", cyl: "-2.25"},
  {power: "3.25", axis: "40", cyl: "-2.25"},
  {power: "3.5", axis: "40", cyl: "-2.25"},
  {power: "3.75", axis: "40", cyl: "-2.25"},
  {power: "4", axis: "40", cyl: "-2.25"},
  {power: "4.25", axis: "40", cyl: "-2.25"},
  {power: "4.5", axis: "40", cyl: "-2.25"},
  {power: "4.75", axis: "40", cyl: "-2.25"},
  {power: "5", axis: "40", cyl: "-2.25"},
  {power: "5.25", axis: "40", cyl: "-2.25"},
  {power: "5.5", axis: "40", cyl: "-2.25"},
  {power: "5.75", axis: "40", cyl: "-2.25"},
  {power: "6", axis: "40", cyl: "-2.25"},
  {power: "0", axis: "50", cyl: "-2.25"},
  {power: "-0.25", axis: "50", cyl: "-2.25"},
  {power: "-0.5", axis: "50", cyl: "-2.25"},
  {power: "-0.75", axis: "50", cyl: "-2.25"},
  {power: "-1", axis: "50", cyl: "-2.25"},
  {power: "-1.25", axis: "50", cyl: "-2.25"},
  {power: "-1.5", axis: "50", cyl: "-2.25"},
  {power: "-1.75", axis: "50", cyl: "-2.25"},
  {power: "-2", axis: "50", cyl: "-2.25"},
  {power: "-2.25", axis: "50", cyl: "-2.25"},
  {power: "-2.5", axis: "50", cyl: "-2.25"},
  {power: "-2.75", axis: "50", cyl: "-2.25"},
  {power: "-3", axis: "50", cyl: "-2.25"},
  {power: "-3.25", axis: "50", cyl: "-2.25"},
  {power: "-3.5", axis: "50", cyl: "-2.25"},
  {power: "-3.75", axis: "50", cyl: "-2.25"},
  {power: "-4", axis: "50", cyl: "-2.25"},
  {power: "-4.25", axis: "50", cyl: "-2.25"},
  {power: "-4.5", axis: "50", cyl: "-2.25"},
  {power: "-4.75", axis: "50", cyl: "-2.25"},
  {power: "-5", axis: "50", cyl: "-2.25"},
  {power: "-5.25", axis: "50", cyl: "-2.25"},
  {power: "-5.5", axis: "50", cyl: "-2.25"},
  {power: "-5.75", axis: "50", cyl: "-2.25"},
  {power: "-6", axis: "50", cyl: "-2.25"},
  {power: "-6.5", axis: "50", cyl: "-2.25"},
  {power: "-7", axis: "50", cyl: "-2.25"},
  {power: "-7.5", axis: "50", cyl: "-2.25"},
  {power: "-8", axis: "50", cyl: "-2.25"},
  {power: "-8.5", axis: "50", cyl: "-2.25"},
  {power: "-9", axis: "50", cyl: "-2.25"},
  {power: "0.25", axis: "50", cyl: "-2.25"},
  {power: "0.5", axis: "50", cyl: "-2.25"},
  {power: "0.75", axis: "50", cyl: "-2.25"},
  {power: "1", axis: "50", cyl: "-2.25"},
  {power: "1.25", axis: "50", cyl: "-2.25"},
  {power: "1.5", axis: "50", cyl: "-2.25"},
  {power: "1.75", axis: "50", cyl: "-2.25"},
  {power: "2", axis: "50", cyl: "-2.25"},
  {power: "2.25", axis: "50", cyl: "-2.25"},
  {power: "2.5", axis: "50", cyl: "-2.25"},
  {power: "2.75", axis: "50", cyl: "-2.25"},
  {power: "3", axis: "50", cyl: "-2.25"},
  {power: "3.25", axis: "50", cyl: "-2.25"},
  {power: "3.5", axis: "50", cyl: "-2.25"},
  {power: "3.75", axis: "50", cyl: "-2.25"},
  {power: "4", axis: "50", cyl: "-2.25"},
  {power: "4.25", axis: "50", cyl: "-2.25"},
  {power: "4.5", axis: "50", cyl: "-2.25"},
  {power: "4.75", axis: "50", cyl: "-2.25"},
  {power: "5", axis: "50", cyl: "-2.25"},
  {power: "5.25", axis: "50", cyl: "-2.25"},
  {power: "5.5", axis: "50", cyl: "-2.25"},
  {power: "5.75", axis: "50", cyl: "-2.25"},
  {power: "6", axis: "50", cyl: "-2.25"},
  {power: "0", axis: "60", cyl: "-2.25"},
  {power: "-0.25", axis: "60", cyl: "-2.25"},
  {power: "-0.5", axis: "60", cyl: "-2.25"},
  {power: "-0.75", axis: "60", cyl: "-2.25"},
  {power: "-1", axis: "60", cyl: "-2.25"},
  {power: "-1.25", axis: "60", cyl: "-2.25"},
  {power: "-1.5", axis: "60", cyl: "-2.25"},
  {power: "-1.75", axis: "60", cyl: "-2.25"},
  {power: "-2", axis: "60", cyl: "-2.25"},
  {power: "-2.25", axis: "60", cyl: "-2.25"},
  {power: "-2.5", axis: "60", cyl: "-2.25"},
  {power: "-2.75", axis: "60", cyl: "-2.25"},
  {power: "-3", axis: "60", cyl: "-2.25"},
  {power: "-3.25", axis: "60", cyl: "-2.25"},
  {power: "-3.5", axis: "60", cyl: "-2.25"},
  {power: "-3.75", axis: "60", cyl: "-2.25"},
  {power: "-4", axis: "60", cyl: "-2.25"},
  {power: "-4.25", axis: "60", cyl: "-2.25"},
  {power: "-4.5", axis: "60", cyl: "-2.25"},
  {power: "-4.75", axis: "60", cyl: "-2.25"},
  {power: "-5", axis: "60", cyl: "-2.25"},
  {power: "-5.25", axis: "60", cyl: "-2.25"},
  {power: "-5.5", axis: "60", cyl: "-2.25"},
  {power: "-5.75", axis: "60", cyl: "-2.25"},
  {power: "-6", axis: "60", cyl: "-2.25"},
  {power: "-6.5", axis: "60", cyl: "-2.25"},
  {power: "-7", axis: "60", cyl: "-2.25"},
  {power: "-7.5", axis: "60", cyl: "-2.25"},
  {power: "-8", axis: "60", cyl: "-2.25"},
  {power: "-8.5", axis: "60", cyl: "-2.25"},
  {power: "-9", axis: "60", cyl: "-2.25"},
  {power: "0.25", axis: "60", cyl: "-2.25"},
  {power: "0.5", axis: "60", cyl: "-2.25"},
  {power: "0.75", axis: "60", cyl: "-2.25"},
  {power: "1", axis: "60", cyl: "-2.25"},
  {power: "1.25", axis: "60", cyl: "-2.25"},
  {power: "1.5", axis: "60", cyl: "-2.25"},
  {power: "1.75", axis: "60", cyl: "-2.25"},
  {power: "2", axis: "60", cyl: "-2.25"},
  {power: "2.25", axis: "60", cyl: "-2.25"},
  {power: "2.5", axis: "60", cyl: "-2.25"},
  {power: "2.75", axis: "60", cyl: "-2.25"},
  {power: "3", axis: "60", cyl: "-2.25"},
  {power: "3.25", axis: "60", cyl: "-2.25"},
  {power: "3.5", axis: "60", cyl: "-2.25"},
  {power: "3.75", axis: "60", cyl: "-2.25"},
  {power: "4", axis: "60", cyl: "-2.25"},
  {power: "4.25", axis: "60", cyl: "-2.25"},
  {power: "4.5", axis: "60", cyl: "-2.25"},
  {power: "4.75", axis: "60", cyl: "-2.25"},
  {power: "5", axis: "60", cyl: "-2.25"},
  {power: "5.25", axis: "60", cyl: "-2.25"},
  {power: "5.5", axis: "60", cyl: "-2.25"},
  {power: "5.75", axis: "60", cyl: "-2.25"},
  {power: "6", axis: "60", cyl: "-2.25"},
  {power: "-6.5", axis: "70", cyl: "-2.25"},
  {power: "-7", axis: "70", cyl: "-2.25"},
  {power: "-7.5", axis: "70", cyl: "-2.25"},
  {power: "-8", axis: "70", cyl: "-2.25"},
  {power: "-8.5", axis: "70", cyl: "-2.25"},
  {power: "-9", axis: "70", cyl: "-2.25"},
  {power: "0.25", axis: "70", cyl: "-2.25"},
  {power: "0.5", axis: "70", cyl: "-2.25"},
  {power: "0.75", axis: "70", cyl: "-2.25"},
  {power: "1", axis: "70", cyl: "-2.25"},
  {power: "1.25", axis: "70", cyl: "-2.25"},
  {power: "1.5", axis: "70", cyl: "-2.25"},
  {power: "1.75", axis: "70", cyl: "-2.25"},
  {power: "2", axis: "70", cyl: "-2.25"},
  {power: "2.25", axis: "70", cyl: "-2.25"},
  {power: "2.5", axis: "70", cyl: "-2.25"},
  {power: "2.75", axis: "70", cyl: "-2.25"},
  {power: "3", axis: "70", cyl: "-2.25"},
  {power: "3.25", axis: "70", cyl: "-2.25"},
  {power: "3.5", axis: "70", cyl: "-2.25"},
  {power: "3.75", axis: "70", cyl: "-2.25"},
  {power: "4", axis: "70", cyl: "-2.25"},
  {power: "4.25", axis: "70", cyl: "-2.25"},
  {power: "4.5", axis: "70", cyl: "-2.25"},
  {power: "4.75", axis: "70", cyl: "-2.25"},
  {power: "5", axis: "70", cyl: "-2.25"},
  {power: "5.25", axis: "70", cyl: "-2.25"},
  {power: "5.5", axis: "70", cyl: "-2.25"},
  {power: "5.75", axis: "70", cyl: "-2.25"},
  {power: "6", axis: "70", cyl: "-2.25"},
  {power: "-6.5", axis: "80", cyl: "-2.25"},
  {power: "-7", axis: "80", cyl: "-2.25"},
  {power: "-7.5", axis: "80", cyl: "-2.25"},
  {power: "-8", axis: "80", cyl: "-2.25"},
  {power: "-8.5", axis: "80", cyl: "-2.25"},
  {power: "-9", axis: "80", cyl: "-2.25"},
  {power: "0.25", axis: "80", cyl: "-2.25"},
  {power: "0.5", axis: "80", cyl: "-2.25"},
  {power: "0.75", axis: "80", cyl: "-2.25"},
  {power: "1", axis: "80", cyl: "-2.25"},
  {power: "1.25", axis: "80", cyl: "-2.25"},
  {power: "1.5", axis: "80", cyl: "-2.25"},
  {power: "1.75", axis: "80", cyl: "-2.25"},
  {power: "2", axis: "80", cyl: "-2.25"},
  {power: "2.25", axis: "80", cyl: "-2.25"},
  {power: "2.5", axis: "80", cyl: "-2.25"},
  {power: "2.75", axis: "80", cyl: "-2.25"},
  {power: "3", axis: "80", cyl: "-2.25"},
  {power: "3.25", axis: "80", cyl: "-2.25"},
  {power: "3.5", axis: "80", cyl: "-2.25"},
  {power: "3.75", axis: "80", cyl: "-2.25"},
  {power: "4", axis: "80", cyl: "-2.25"},
  {power: "4.25", axis: "80", cyl: "-2.25"},
  {power: "4.5", axis: "80", cyl: "-2.25"},
  {power: "4.75", axis: "80", cyl: "-2.25"},
  {power: "5", axis: "80", cyl: "-2.25"},
  {power: "5.25", axis: "80", cyl: "-2.25"},
  {power: "5.5", axis: "80", cyl: "-2.25"},
  {power: "5.75", axis: "80", cyl: "-2.25"},
  {power: "6", axis: "80", cyl: "-2.25"},
  {power: "-6.5", axis: "90", cyl: "-2.25"},
  {power: "-7", axis: "90", cyl: "-2.25"},
  {power: "-7.5", axis: "90", cyl: "-2.25"},
  {power: "-8", axis: "90", cyl: "-2.25"},
  {power: "-8.5", axis: "90", cyl: "-2.25"},
  {power: "-9", axis: "90", cyl: "-2.25"},
  {power: "0.25", axis: "90", cyl: "-2.25"},
  {power: "0.5", axis: "90", cyl: "-2.25"},
  {power: "0.75", axis: "90", cyl: "-2.25"},
  {power: "1", axis: "90", cyl: "-2.25"},
  {power: "1.25", axis: "90", cyl: "-2.25"},
  {power: "1.5", axis: "90", cyl: "-2.25"},
  {power: "1.75", axis: "90", cyl: "-2.25"},
  {power: "2", axis: "90", cyl: "-2.25"},
  {power: "2.25", axis: "90", cyl: "-2.25"},
  {power: "2.5", axis: "90", cyl: "-2.25"},
  {power: "2.75", axis: "90", cyl: "-2.25"},
  {power: "3", axis: "90", cyl: "-2.25"},
  {power: "3.25", axis: "90", cyl: "-2.25"},
  {power: "3.5", axis: "90", cyl: "-2.25"},
  {power: "3.75", axis: "90", cyl: "-2.25"},
  {power: "4", axis: "90", cyl: "-2.25"},
  {power: "4.25", axis: "90", cyl: "-2.25"},
  {power: "4.5", axis: "90", cyl: "-2.25"},
  {power: "4.75", axis: "90", cyl: "-2.25"},
  {power: "5", axis: "90", cyl: "-2.25"},
  {power: "5.25", axis: "90", cyl: "-2.25"},
  {power: "5.5", axis: "90", cyl: "-2.25"},
  {power: "5.75", axis: "90", cyl: "-2.25"},
  {power: "6", axis: "90", cyl: "-2.25"},
  {power: "-6.5", axis: "100", cyl: "-2.25"},
  {power: "-7", axis: "100", cyl: "-2.25"},
  {power: "-7.5", axis: "100", cyl: "-2.25"},
  {power: "-8", axis: "100", cyl: "-2.25"},
  {power: "-8.5", axis: "100", cyl: "-2.25"},
  {power: "-9", axis: "100", cyl: "-2.25"},
  {power: "0.25", axis: "100", cyl: "-2.25"},
  {power: "0.5", axis: "100", cyl: "-2.25"},
  {power: "0.75", axis: "100", cyl: "-2.25"},
  {power: "1", axis: "100", cyl: "-2.25"},
  {power: "1.25", axis: "100", cyl: "-2.25"},
  {power: "1.5", axis: "100", cyl: "-2.25"},
  {power: "1.75", axis: "100", cyl: "-2.25"},
  {power: "2", axis: "100", cyl: "-2.25"},
  {power: "2.25", axis: "100", cyl: "-2.25"},
  {power: "2.5", axis: "100", cyl: "-2.25"},
  {power: "2.75", axis: "100", cyl: "-2.25"},
  {power: "3", axis: "100", cyl: "-2.25"},
  {power: "3.25", axis: "100", cyl: "-2.25"},
  {power: "3.5", axis: "100", cyl: "-2.25"},
  {power: "3.75", axis: "100", cyl: "-2.25"},
  {power: "4", axis: "100", cyl: "-2.25"},
  {power: "4.25", axis: "100", cyl: "-2.25"},
  {power: "4.5", axis: "100", cyl: "-2.25"},
  {power: "4.75", axis: "100", cyl: "-2.25"},
  {power: "5", axis: "100", cyl: "-2.25"},
  {power: "5.25", axis: "100", cyl: "-2.25"},
  {power: "5.5", axis: "100", cyl: "-2.25"},
  {power: "5.75", axis: "100", cyl: "-2.25"},
  {power: "6", axis: "100", cyl: "-2.25"},
  {power: "-6.5", axis: "110", cyl: "-2.25"},
  {power: "-7", axis: "110", cyl: "-2.25"},
  {power: "-7.5", axis: "110", cyl: "-2.25"},
  {power: "-8", axis: "110", cyl: "-2.25"},
  {power: "-8.5", axis: "110", cyl: "-2.25"},
  {power: "-9", axis: "110", cyl: "-2.25"},
  {power: "0.25", axis: "110", cyl: "-2.25"},
  {power: "0.5", axis: "110", cyl: "-2.25"},
  {power: "0.75", axis: "110", cyl: "-2.25"},
  {power: "1", axis: "110", cyl: "-2.25"},
  {power: "1.25", axis: "110", cyl: "-2.25"},
  {power: "1.5", axis: "110", cyl: "-2.25"},
  {power: "1.75", axis: "110", cyl: "-2.25"},
  {power: "2", axis: "110", cyl: "-2.25"},
  {power: "2.25", axis: "110", cyl: "-2.25"},
  {power: "2.5", axis: "110", cyl: "-2.25"},
  {power: "2.75", axis: "110", cyl: "-2.25"},
  {power: "3", axis: "110", cyl: "-2.25"},
  {power: "3.25", axis: "110", cyl: "-2.25"},
  {power: "3.5", axis: "110", cyl: "-2.25"},
  {power: "3.75", axis: "110", cyl: "-2.25"},
  {power: "4", axis: "110", cyl: "-2.25"},
  {power: "4.25", axis: "110", cyl: "-2.25"},
  {power: "4.5", axis: "110", cyl: "-2.25"},
  {power: "4.75", axis: "110", cyl: "-2.25"},
  {power: "5", axis: "110", cyl: "-2.25"},
  {power: "5.25", axis: "110", cyl: "-2.25"},
  {power: "5.5", axis: "110", cyl: "-2.25"},
  {power: "5.75", axis: "110", cyl: "-2.25"},
  {power: "6", axis: "110", cyl: "-2.25"},
  {power: "0", axis: "120", cyl: "-2.25"},
  {power: "-0.25", axis: "120", cyl: "-2.25"},
  {power: "-0.5", axis: "120", cyl: "-2.25"},
  {power: "-0.75", axis: "120", cyl: "-2.25"},
  {power: "-1", axis: "120", cyl: "-2.25"},
  {power: "-1.25", axis: "120", cyl: "-2.25"},
  {power: "-1.5", axis: "120", cyl: "-2.25"},
  {power: "-1.75", axis: "120", cyl: "-2.25"},
  {power: "-2", axis: "120", cyl: "-2.25"},
  {power: "-2.25", axis: "120", cyl: "-2.25"},
  {power: "-2.5", axis: "120", cyl: "-2.25"},
  {power: "-2.75", axis: "120", cyl: "-2.25"},
  {power: "-3", axis: "120", cyl: "-2.25"},
  {power: "-3.25", axis: "120", cyl: "-2.25"},
  {power: "-3.5", axis: "120", cyl: "-2.25"},
  {power: "-3.75", axis: "120", cyl: "-2.25"},
  {power: "-4", axis: "120", cyl: "-2.25"},
  {power: "-4.25", axis: "120", cyl: "-2.25"},
  {power: "-4.5", axis: "120", cyl: "-2.25"},
  {power: "-4.75", axis: "120", cyl: "-2.25"},
  {power: "-5", axis: "120", cyl: "-2.25"},
  {power: "-5.25", axis: "120", cyl: "-2.25"},
  {power: "-5.5", axis: "120", cyl: "-2.25"},
  {power: "-5.75", axis: "120", cyl: "-2.25"},
  {power: "-6", axis: "120", cyl: "-2.25"},
  {power: "-6.5", axis: "120", cyl: "-2.25"},
  {power: "-7", axis: "120", cyl: "-2.25"},
  {power: "-7.5", axis: "120", cyl: "-2.25"},
  {power: "-8", axis: "120", cyl: "-2.25"},
  {power: "-8.5", axis: "120", cyl: "-2.25"},
  {power: "-9", axis: "120", cyl: "-2.25"},
  {power: "0.25", axis: "120", cyl: "-2.25"},
  {power: "0.5", axis: "120", cyl: "-2.25"},
  {power: "0.75", axis: "120", cyl: "-2.25"},
  {power: "1", axis: "120", cyl: "-2.25"},
  {power: "1.25", axis: "120", cyl: "-2.25"},
  {power: "1.5", axis: "120", cyl: "-2.25"},
  {power: "1.75", axis: "120", cyl: "-2.25"},
  {power: "2", axis: "120", cyl: "-2.25"},
  {power: "2.25", axis: "120", cyl: "-2.25"},
  {power: "2.5", axis: "120", cyl: "-2.25"},
  {power: "2.75", axis: "120", cyl: "-2.25"},
  {power: "3", axis: "120", cyl: "-2.25"},
  {power: "3.25", axis: "120", cyl: "-2.25"},
  {power: "3.5", axis: "120", cyl: "-2.25"},
  {power: "3.75", axis: "120", cyl: "-2.25"},
  {power: "4", axis: "120", cyl: "-2.25"},
  {power: "4.25", axis: "120", cyl: "-2.25"},
  {power: "4.5", axis: "120", cyl: "-2.25"},
  {power: "4.75", axis: "120", cyl: "-2.25"},
  {power: "5", axis: "120", cyl: "-2.25"},
  {power: "5.25", axis: "120", cyl: "-2.25"},
  {power: "5.5", axis: "120", cyl: "-2.25"},
  {power: "5.75", axis: "120", cyl: "-2.25"},
  {power: "6", axis: "120", cyl: "-2.25"},
  {power: "0", axis: "130", cyl: "-2.25"},
  {power: "-0.25", axis: "130", cyl: "-2.25"},
  {power: "-0.5", axis: "130", cyl: "-2.25"},
  {power: "-0.75", axis: "130", cyl: "-2.25"},
  {power: "-1", axis: "130", cyl: "-2.25"},
  {power: "-1.25", axis: "130", cyl: "-2.25"},
  {power: "-1.5", axis: "130", cyl: "-2.25"},
  {power: "-1.75", axis: "130", cyl: "-2.25"},
  {power: "-2", axis: "130", cyl: "-2.25"},
  {power: "-2.25", axis: "130", cyl: "-2.25"},
  {power: "-2.5", axis: "130", cyl: "-2.25"},
  {power: "-2.75", axis: "130", cyl: "-2.25"},
  {power: "-3", axis: "130", cyl: "-2.25"},
  {power: "-3.25", axis: "130", cyl: "-2.25"},
  {power: "-3.5", axis: "130", cyl: "-2.25"},
  {power: "-3.75", axis: "130", cyl: "-2.25"},
  {power: "-4", axis: "130", cyl: "-2.25"},
  {power: "-4.25", axis: "130", cyl: "-2.25"},
  {power: "-4.5", axis: "130", cyl: "-2.25"},
  {power: "-4.75", axis: "130", cyl: "-2.25"},
  {power: "-5", axis: "130", cyl: "-2.25"},
  {power: "-5.25", axis: "130", cyl: "-2.25"},
  {power: "-5.5", axis: "130", cyl: "-2.25"},
  {power: "-5.75", axis: "130", cyl: "-2.25"},
  {power: "-6", axis: "130", cyl: "-2.25"},
  {power: "-6.5", axis: "130", cyl: "-2.25"},
  {power: "-7", axis: "130", cyl: "-2.25"},
  {power: "-7.5", axis: "130", cyl: "-2.25"},
  {power: "-8", axis: "130", cyl: "-2.25"},
  {power: "-8.5", axis: "130", cyl: "-2.25"},
  {power: "-9", axis: "130", cyl: "-2.25"},
  {power: "0.25", axis: "130", cyl: "-2.25"},
  {power: "0.5", axis: "130", cyl: "-2.25"},
  {power: "0.75", axis: "130", cyl: "-2.25"},
  {power: "1", axis: "130", cyl: "-2.25"},
  {power: "1.25", axis: "130", cyl: "-2.25"},
  {power: "1.5", axis: "130", cyl: "-2.25"},
  {power: "1.75", axis: "130", cyl: "-2.25"},
  {power: "2", axis: "130", cyl: "-2.25"},
  {power: "2.25", axis: "130", cyl: "-2.25"},
  {power: "2.5", axis: "130", cyl: "-2.25"},
  {power: "2.75", axis: "130", cyl: "-2.25"},
  {power: "3", axis: "130", cyl: "-2.25"},
  {power: "3.25", axis: "130", cyl: "-2.25"},
  {power: "3.5", axis: "130", cyl: "-2.25"},
  {power: "3.75", axis: "130", cyl: "-2.25"},
  {power: "4", axis: "130", cyl: "-2.25"},
  {power: "4.25", axis: "130", cyl: "-2.25"},
  {power: "4.5", axis: "130", cyl: "-2.25"},
  {power: "4.75", axis: "130", cyl: "-2.25"},
  {power: "5", axis: "130", cyl: "-2.25"},
  {power: "5.25", axis: "130", cyl: "-2.25"},
  {power: "5.5", axis: "130", cyl: "-2.25"},
  {power: "5.75", axis: "130", cyl: "-2.25"},
  {power: "6", axis: "130", cyl: "-2.25"},
  {power: "0", axis: "140", cyl: "-2.25"},
  {power: "-0.25", axis: "140", cyl: "-2.25"},
  {power: "-0.5", axis: "140", cyl: "-2.25"},
  {power: "-0.75", axis: "140", cyl: "-2.25"},
  {power: "-1", axis: "140", cyl: "-2.25"},
  {power: "-1.25", axis: "140", cyl: "-2.25"},
  {power: "-1.5", axis: "140", cyl: "-2.25"},
  {power: "-1.75", axis: "140", cyl: "-2.25"},
  {power: "-2", axis: "140", cyl: "-2.25"},
  {power: "-2.25", axis: "140", cyl: "-2.25"},
  {power: "-2.5", axis: "140", cyl: "-2.25"},
  {power: "-2.75", axis: "140", cyl: "-2.25"},
  {power: "-3", axis: "140", cyl: "-2.25"},
  {power: "-3.25", axis: "140", cyl: "-2.25"},
  {power: "-3.5", axis: "140", cyl: "-2.25"},
  {power: "-3.75", axis: "140", cyl: "-2.25"},
  {power: "-4", axis: "140", cyl: "-2.25"},
  {power: "-4.25", axis: "140", cyl: "-2.25"},
  {power: "-4.5", axis: "140", cyl: "-2.25"},
  {power: "-4.75", axis: "140", cyl: "-2.25"},
  {power: "-5", axis: "140", cyl: "-2.25"},
  {power: "-5.25", axis: "140", cyl: "-2.25"},
  {power: "-5.5", axis: "140", cyl: "-2.25"},
  {power: "-5.75", axis: "140", cyl: "-2.25"},
  {power: "-6", axis: "140", cyl: "-2.25"},
  {power: "-6.5", axis: "140", cyl: "-2.25"},
  {power: "-7", axis: "140", cyl: "-2.25"},
  {power: "-7.5", axis: "140", cyl: "-2.25"},
  {power: "-8", axis: "140", cyl: "-2.25"},
  {power: "-8.5", axis: "140", cyl: "-2.25"},
  {power: "-9", axis: "140", cyl: "-2.25"},
  {power: "0.25", axis: "140", cyl: "-2.25"},
  {power: "0.5", axis: "140", cyl: "-2.25"},
  {power: "0.75", axis: "140", cyl: "-2.25"},
  {power: "1", axis: "140", cyl: "-2.25"},
  {power: "1.25", axis: "140", cyl: "-2.25"},
  {power: "1.5", axis: "140", cyl: "-2.25"},
  {power: "1.75", axis: "140", cyl: "-2.25"},
  {power: "2", axis: "140", cyl: "-2.25"},
  {power: "2.25", axis: "140", cyl: "-2.25"},
  {power: "2.5", axis: "140", cyl: "-2.25"},
  {power: "2.75", axis: "140", cyl: "-2.25"},
  {power: "3", axis: "140", cyl: "-2.25"},
  {power: "3.25", axis: "140", cyl: "-2.25"},
  {power: "3.5", axis: "140", cyl: "-2.25"},
  {power: "3.75", axis: "140", cyl: "-2.25"},
  {power: "4", axis: "140", cyl: "-2.25"},
  {power: "4.25", axis: "140", cyl: "-2.25"},
  {power: "4.5", axis: "140", cyl: "-2.25"},
  {power: "4.75", axis: "140", cyl: "-2.25"},
  {power: "5", axis: "140", cyl: "-2.25"},
  {power: "5.25", axis: "140", cyl: "-2.25"},
  {power: "5.5", axis: "140", cyl: "-2.25"},
  {power: "5.75", axis: "140", cyl: "-2.25"},
  {power: "6", axis: "140", cyl: "-2.25"},
  {power: "0", axis: "150", cyl: "-2.25"},
  {power: "-0.25", axis: "150", cyl: "-2.25"},
  {power: "-0.5", axis: "150", cyl: "-2.25"},
  {power: "-0.75", axis: "150", cyl: "-2.25"},
  {power: "-1", axis: "150", cyl: "-2.25"},
  {power: "-1.25", axis: "150", cyl: "-2.25"},
  {power: "-1.5", axis: "150", cyl: "-2.25"},
  {power: "-1.75", axis: "150", cyl: "-2.25"},
  {power: "-2", axis: "150", cyl: "-2.25"},
  {power: "-2.25", axis: "150", cyl: "-2.25"},
  {power: "-2.5", axis: "150", cyl: "-2.25"},
  {power: "-2.75", axis: "150", cyl: "-2.25"},
  {power: "-3", axis: "150", cyl: "-2.25"},
  {power: "-3.25", axis: "150", cyl: "-2.25"},
  {power: "-3.5", axis: "150", cyl: "-2.25"},
  {power: "-3.75", axis: "150", cyl: "-2.25"},
  {power: "-4", axis: "150", cyl: "-2.25"},
  {power: "-4.25", axis: "150", cyl: "-2.25"},
  {power: "-4.5", axis: "150", cyl: "-2.25"},
  {power: "-4.75", axis: "150", cyl: "-2.25"},
  {power: "-5", axis: "150", cyl: "-2.25"},
  {power: "-5.25", axis: "150", cyl: "-2.25"},
  {power: "-5.5", axis: "150", cyl: "-2.25"},
  {power: "-5.75", axis: "150", cyl: "-2.25"},
  {power: "-6", axis: "150", cyl: "-2.25"},
  {power: "-6.5", axis: "150", cyl: "-2.25"},
  {power: "-7", axis: "150", cyl: "-2.25"},
  {power: "-7.5", axis: "150", cyl: "-2.25"},
  {power: "-8", axis: "150", cyl: "-2.25"},
  {power: "-8.5", axis: "150", cyl: "-2.25"},
  {power: "-9", axis: "150", cyl: "-2.25"},
  {power: "0.25", axis: "150", cyl: "-2.25"},
  {power: "0.5", axis: "150", cyl: "-2.25"},
  {power: "0.75", axis: "150", cyl: "-2.25"},
  {power: "1", axis: "150", cyl: "-2.25"},
  {power: "1.25", axis: "150", cyl: "-2.25"},
  {power: "1.5", axis: "150", cyl: "-2.25"},
  {power: "1.75", axis: "150", cyl: "-2.25"},
  {power: "2", axis: "150", cyl: "-2.25"},
  {power: "2.25", axis: "150", cyl: "-2.25"},
  {power: "2.5", axis: "150", cyl: "-2.25"},
  {power: "2.75", axis: "150", cyl: "-2.25"},
  {power: "3", axis: "150", cyl: "-2.25"},
  {power: "3.25", axis: "150", cyl: "-2.25"},
  {power: "3.5", axis: "150", cyl: "-2.25"},
  {power: "3.75", axis: "150", cyl: "-2.25"},
  {power: "4", axis: "150", cyl: "-2.25"},
  {power: "4.25", axis: "150", cyl: "-2.25"},
  {power: "4.5", axis: "150", cyl: "-2.25"},
  {power: "4.75", axis: "150", cyl: "-2.25"},
  {power: "5", axis: "150", cyl: "-2.25"},
  {power: "5.25", axis: "150", cyl: "-2.25"},
  {power: "5.5", axis: "150", cyl: "-2.25"},
  {power: "5.75", axis: "150", cyl: "-2.25"},
  {power: "6", axis: "150", cyl: "-2.25"},
  {power: "0.25", axis: "160", cyl: "-2.25"},
  {power: "0.5", axis: "160", cyl: "-2.25"},
  {power: "0.75", axis: "160", cyl: "-2.25"},
  {power: "1", axis: "160", cyl: "-2.25"},
  {power: "1.25", axis: "160", cyl: "-2.25"},
  {power: "1.5", axis: "160", cyl: "-2.25"},
  {power: "1.75", axis: "160", cyl: "-2.25"},
  {power: "2", axis: "160", cyl: "-2.25"},
  {power: "2.25", axis: "160", cyl: "-2.25"},
  {power: "2.5", axis: "160", cyl: "-2.25"},
  {power: "2.75", axis: "160", cyl: "-2.25"},
  {power: "3", axis: "160", cyl: "-2.25"},
  {power: "3.25", axis: "160", cyl: "-2.25"},
  {power: "3.5", axis: "160", cyl: "-2.25"},
  {power: "3.75", axis: "160", cyl: "-2.25"},
  {power: "4", axis: "160", cyl: "-2.25"},
  {power: "4.25", axis: "160", cyl: "-2.25"},
  {power: "4.5", axis: "160", cyl: "-2.25"},
  {power: "4.75", axis: "160", cyl: "-2.25"},
  {power: "5", axis: "160", cyl: "-2.25"},
  {power: "5.25", axis: "160", cyl: "-2.25"},
  {power: "5.5", axis: "160", cyl: "-2.25"},
  {power: "5.75", axis: "160", cyl: "-2.25"},
  {power: "6", axis: "160", cyl: "-2.25"},
  {power: "0.25", axis: "170", cyl: "-2.25"},
  {power: "0.5", axis: "170", cyl: "-2.25"},
  {power: "0.75", axis: "170", cyl: "-2.25"},
  {power: "1", axis: "170", cyl: "-2.25"},
  {power: "1.25", axis: "170", cyl: "-2.25"},
  {power: "1.5", axis: "170", cyl: "-2.25"},
  {power: "1.75", axis: "170", cyl: "-2.25"},
  {power: "2", axis: "170", cyl: "-2.25"},
  {power: "2.25", axis: "170", cyl: "-2.25"},
  {power: "2.5", axis: "170", cyl: "-2.25"},
  {power: "2.75", axis: "170", cyl: "-2.25"},
  {power: "3", axis: "170", cyl: "-2.25"},
  {power: "3.25", axis: "170", cyl: "-2.25"},
  {power: "3.5", axis: "170", cyl: "-2.25"},
  {power: "3.75", axis: "170", cyl: "-2.25"},
  {power: "4", axis: "170", cyl: "-2.25"},
  {power: "4.25", axis: "170", cyl: "-2.25"},
  {power: "4.5", axis: "170", cyl: "-2.25"},
  {power: "4.75", axis: "170", cyl: "-2.25"},
  {power: "5", axis: "170", cyl: "-2.25"},
  {power: "5.25", axis: "170", cyl: "-2.25"},
  {power: "5.5", axis: "170", cyl: "-2.25"},
  {power: "5.75", axis: "170", cyl: "-2.25"},
  {power: "6", axis: "170", cyl: "-2.25"},
  {power: "0.25", axis: "180", cyl: "-2.25"},
  {power: "0.5", axis: "180", cyl: "-2.25"},
  {power: "0.75", axis: "180", cyl: "-2.25"},
  {power: "1", axis: "180", cyl: "-2.25"},
  {power: "1.25", axis: "180", cyl: "-2.25"},
  {power: "1.5", axis: "180", cyl: "-2.25"},
  {power: "1.75", axis: "180", cyl: "-2.25"},
  {power: "2", axis: "180", cyl: "-2.25"},
  {power: "2.25", axis: "180", cyl: "-2.25"},
  {power: "2.5", axis: "180", cyl: "-2.25"},
  {power: "2.75", axis: "180", cyl: "-2.25"},
  {power: "3", axis: "180", cyl: "-2.25"},
  {power: "3.25", axis: "180", cyl: "-2.25"},
  {power: "3.5", axis: "180", cyl: "-2.25"},
  {power: "3.75", axis: "180", cyl: "-2.25"},
  {power: "4", axis: "180", cyl: "-2.25"},
  {power: "4.25", axis: "180", cyl: "-2.25"},
  {power: "4.5", axis: "180", cyl: "-2.25"},
  {power: "4.75", axis: "180", cyl: "-2.25"},
  {power: "5", axis: "180", cyl: "-2.25"},
  {power: "5.25", axis: "180", cyl: "-2.25"},
  {power: "5.5", axis: "180", cyl: "-2.25"},
  {power: "5.75", axis: "180", cyl: "-2.25"},
  {power: "6", axis: "180", cyl: "-2.25"},
  {power: "0.25", axis: "10", cyl: "-2.75"},
  {power: "0.5", axis: "10", cyl: "-2.75"},
  {power: "0.75", axis: "10", cyl: "-2.75"},
  {power: "1", axis: "10", cyl: "-2.75"},
  {power: "1.25", axis: "10", cyl: "-2.75"},
  {power: "1.5", axis: "10", cyl: "-2.75"},
  {power: "1.75", axis: "10", cyl: "-2.75"},
  {power: "2", axis: "10", cyl: "-2.75"},
  {power: "2.25", axis: "10", cyl: "-2.75"},
  {power: "2.5", axis: "10", cyl: "-2.75"},
  {power: "2.75", axis: "10", cyl: "-2.75"},
  {power: "3", axis: "10", cyl: "-2.75"},
  {power: "3.25", axis: "10", cyl: "-2.75"},
  {power: "3.5", axis: "10", cyl: "-2.75"},
  {power: "3.75", axis: "10", cyl: "-2.75"},
  {power: "4", axis: "10", cyl: "-2.75"},
  {power: "4.25", axis: "10", cyl: "-2.75"},
  {power: "4.5", axis: "10", cyl: "-2.75"},
  {power: "4.75", axis: "10", cyl: "-2.75"},
  {power: "5", axis: "10", cyl: "-2.75"},
  {power: "5.25", axis: "10", cyl: "-2.75"},
  {power: "5.5", axis: "10", cyl: "-2.75"},
  {power: "5.75", axis: "10", cyl: "-2.75"},
  {power: "6", axis: "10", cyl: "-2.75"},
  {power: "0.25", axis: "20", cyl: "-2.75"},
  {power: "0.5", axis: "20", cyl: "-2.75"},
  {power: "0.75", axis: "20", cyl: "-2.75"},
  {power: "1", axis: "20", cyl: "-2.75"},
  {power: "1.25", axis: "20", cyl: "-2.75"},
  {power: "1.5", axis: "20", cyl: "-2.75"},
  {power: "1.75", axis: "20", cyl: "-2.75"},
  {power: "2", axis: "20", cyl: "-2.75"},
  {power: "2.25", axis: "20", cyl: "-2.75"},
  {power: "2.5", axis: "20", cyl: "-2.75"},
  {power: "2.75", axis: "20", cyl: "-2.75"},
  {power: "3", axis: "20", cyl: "-2.75"},
  {power: "3.25", axis: "20", cyl: "-2.75"},
  {power: "3.5", axis: "20", cyl: "-2.75"},
  {power: "3.75", axis: "20", cyl: "-2.75"},
  {power: "4", axis: "20", cyl: "-2.75"},
  {power: "4.25", axis: "20", cyl: "-2.75"},
  {power: "4.5", axis: "20", cyl: "-2.75"},
  {power: "4.75", axis: "20", cyl: "-2.75"},
  {power: "5", axis: "20", cyl: "-2.75"},
  {power: "5.25", axis: "20", cyl: "-2.75"},
  {power: "5.5", axis: "20", cyl: "-2.75"},
  {power: "5.75", axis: "20", cyl: "-2.75"},
  {power: "6", axis: "20", cyl: "-2.75"},
  {power: "0", axis: "30", cyl: "-2.75"},
  {power: "-0.25", axis: "30", cyl: "-2.75"},
  {power: "-0.5", axis: "30", cyl: "-2.75"},
  {power: "-0.75", axis: "30", cyl: "-2.75"},
  {power: "-1", axis: "30", cyl: "-2.75"},
  {power: "-1.25", axis: "30", cyl: "-2.75"},
  {power: "-1.5", axis: "30", cyl: "-2.75"},
  {power: "-1.75", axis: "30", cyl: "-2.75"},
  {power: "-2", axis: "30", cyl: "-2.75"},
  {power: "-2.25", axis: "30", cyl: "-2.75"},
  {power: "-2.5", axis: "30", cyl: "-2.75"},
  {power: "-2.75", axis: "30", cyl: "-2.75"},
  {power: "-3", axis: "30", cyl: "-2.75"},
  {power: "-3.25", axis: "30", cyl: "-2.75"},
  {power: "-3.5", axis: "30", cyl: "-2.75"},
  {power: "-3.75", axis: "30", cyl: "-2.75"},
  {power: "-4", axis: "30", cyl: "-2.75"},
  {power: "-4.25", axis: "30", cyl: "-2.75"},
  {power: "-4.5", axis: "30", cyl: "-2.75"},
  {power: "-4.75", axis: "30", cyl: "-2.75"},
  {power: "-5", axis: "30", cyl: "-2.75"},
  {power: "-5.25", axis: "30", cyl: "-2.75"},
  {power: "-5.5", axis: "30", cyl: "-2.75"},
  {power: "-5.75", axis: "30", cyl: "-2.75"},
  {power: "-6", axis: "30", cyl: "-2.75"},
  {power: "-6.5", axis: "30", cyl: "-2.75"},
  {power: "-7", axis: "30", cyl: "-2.75"},
  {power: "-7.5", axis: "30", cyl: "-2.75"},
  {power: "-8", axis: "30", cyl: "-2.75"},
  {power: "-8.5", axis: "30", cyl: "-2.75"},
  {power: "-9", axis: "30", cyl: "-2.75"},
  {power: "0.25", axis: "30", cyl: "-2.75"},
  {power: "0.5", axis: "30", cyl: "-2.75"},
  {power: "0.75", axis: "30", cyl: "-2.75"},
  {power: "1", axis: "30", cyl: "-2.75"},
  {power: "1.25", axis: "30", cyl: "-2.75"},
  {power: "1.5", axis: "30", cyl: "-2.75"},
  {power: "1.75", axis: "30", cyl: "-2.75"},
  {power: "2", axis: "30", cyl: "-2.75"},
  {power: "2.25", axis: "30", cyl: "-2.75"},
  {power: "2.5", axis: "30", cyl: "-2.75"},
  {power: "2.75", axis: "30", cyl: "-2.75"},
  {power: "3", axis: "30", cyl: "-2.75"},
  {power: "3.25", axis: "30", cyl: "-2.75"},
  {power: "3.5", axis: "30", cyl: "-2.75"},
  {power: "3.75", axis: "30", cyl: "-2.75"},
  {power: "4", axis: "30", cyl: "-2.75"},
  {power: "4.25", axis: "30", cyl: "-2.75"},
  {power: "4.5", axis: "30", cyl: "-2.75"},
  {power: "4.75", axis: "30", cyl: "-2.75"},
  {power: "5", axis: "30", cyl: "-2.75"},
  {power: "5.25", axis: "30", cyl: "-2.75"},
  {power: "5.5", axis: "30", cyl: "-2.75"},
  {power: "5.75", axis: "30", cyl: "-2.75"},
  {power: "6", axis: "30", cyl: "-2.75"},
  {power: "0", axis: "40", cyl: "-2.75"},
  {power: "-0.25", axis: "40", cyl: "-2.75"},
  {power: "-0.5", axis: "40", cyl: "-2.75"},
  {power: "-0.75", axis: "40", cyl: "-2.75"},
  {power: "-1", axis: "40", cyl: "-2.75"},
  {power: "-1.25", axis: "40", cyl: "-2.75"},
  {power: "-1.5", axis: "40", cyl: "-2.75"},
  {power: "-1.75", axis: "40", cyl: "-2.75"},
  {power: "-2", axis: "40", cyl: "-2.75"},
  {power: "-2.25", axis: "40", cyl: "-2.75"},
  {power: "-2.5", axis: "40", cyl: "-2.75"},
  {power: "-2.75", axis: "40", cyl: "-2.75"},
  {power: "-3", axis: "40", cyl: "-2.75"},
  {power: "-3.25", axis: "40", cyl: "-2.75"},
  {power: "-3.5", axis: "40", cyl: "-2.75"},
  {power: "-3.75", axis: "40", cyl: "-2.75"},
  {power: "-4", axis: "40", cyl: "-2.75"},
  {power: "-4.25", axis: "40", cyl: "-2.75"},
  {power: "-4.5", axis: "40", cyl: "-2.75"},
  {power: "-4.75", axis: "40", cyl: "-2.75"},
  {power: "-5", axis: "40", cyl: "-2.75"},
  {power: "-5.25", axis: "40", cyl: "-2.75"},
  {power: "-5.5", axis: "40", cyl: "-2.75"},
  {power: "-5.75", axis: "40", cyl: "-2.75"},
  {power: "-6", axis: "40", cyl: "-2.75"},
  {power: "-6.5", axis: "40", cyl: "-2.75"},
  {power: "-7", axis: "40", cyl: "-2.75"},
  {power: "-7.5", axis: "40", cyl: "-2.75"},
  {power: "-8", axis: "40", cyl: "-2.75"},
  {power: "-8.5", axis: "40", cyl: "-2.75"},
  {power: "-9", axis: "40", cyl: "-2.75"},
  {power: "0.25", axis: "40", cyl: "-2.75"},
  {power: "0.5", axis: "40", cyl: "-2.75"},
  {power: "0.75", axis: "40", cyl: "-2.75"},
  {power: "1", axis: "40", cyl: "-2.75"},
  {power: "1.25", axis: "40", cyl: "-2.75"},
  {power: "1.5", axis: "40", cyl: "-2.75"},
  {power: "1.75", axis: "40", cyl: "-2.75"},
  {power: "2", axis: "40", cyl: "-2.75"},
  {power: "2.25", axis: "40", cyl: "-2.75"},
  {power: "2.5", axis: "40", cyl: "-2.75"},
  {power: "2.75", axis: "40", cyl: "-2.75"},
  {power: "3", axis: "40", cyl: "-2.75"},
  {power: "3.25", axis: "40", cyl: "-2.75"},
  {power: "3.5", axis: "40", cyl: "-2.75"},
  {power: "3.75", axis: "40", cyl: "-2.75"},
  {power: "4", axis: "40", cyl: "-2.75"},
  {power: "4.25", axis: "40", cyl: "-2.75"},
  {power: "4.5", axis: "40", cyl: "-2.75"},
  {power: "4.75", axis: "40", cyl: "-2.75"},
  {power: "5", axis: "40", cyl: "-2.75"},
  {power: "5.25", axis: "40", cyl: "-2.75"},
  {power: "5.5", axis: "40", cyl: "-2.75"},
  {power: "5.75", axis: "40", cyl: "-2.75"},
  {power: "6", axis: "40", cyl: "-2.75"},
  {power: "0", axis: "50", cyl: "-2.75"},
  {power: "-0.25", axis: "50", cyl: "-2.75"},
  {power: "-0.5", axis: "50", cyl: "-2.75"},
  {power: "-0.75", axis: "50", cyl: "-2.75"},
  {power: "-1", axis: "50", cyl: "-2.75"},
  {power: "-1.25", axis: "50", cyl: "-2.75"},
  {power: "-1.5", axis: "50", cyl: "-2.75"},
  {power: "-1.75", axis: "50", cyl: "-2.75"},
  {power: "-2", axis: "50", cyl: "-2.75"},
  {power: "-2.25", axis: "50", cyl: "-2.75"},
  {power: "-2.5", axis: "50", cyl: "-2.75"},
  {power: "-2.75", axis: "50", cyl: "-2.75"},
  {power: "-3", axis: "50", cyl: "-2.75"},
  {power: "-3.25", axis: "50", cyl: "-2.75"},
  {power: "-3.5", axis: "50", cyl: "-2.75"},
  {power: "-3.75", axis: "50", cyl: "-2.75"},
  {power: "-4", axis: "50", cyl: "-2.75"},
  {power: "-4.25", axis: "50", cyl: "-2.75"},
  {power: "-4.5", axis: "50", cyl: "-2.75"},
  {power: "-4.75", axis: "50", cyl: "-2.75"},
  {power: "-5", axis: "50", cyl: "-2.75"},
  {power: "-5.25", axis: "50", cyl: "-2.75"},
  {power: "-5.5", axis: "50", cyl: "-2.75"},
  {power: "-5.75", axis: "50", cyl: "-2.75"},
  {power: "-6", axis: "50", cyl: "-2.75"},
  {power: "-6.5", axis: "50", cyl: "-2.75"},
  {power: "-7", axis: "50", cyl: "-2.75"},
  {power: "-7.5", axis: "50", cyl: "-2.75"},
  {power: "-8", axis: "50", cyl: "-2.75"},
  {power: "-8.5", axis: "50", cyl: "-2.75"},
  {power: "-9", axis: "50", cyl: "-2.75"},
  {power: "0.25", axis: "50", cyl: "-2.75"},
  {power: "0.5", axis: "50", cyl: "-2.75"},
  {power: "0.75", axis: "50", cyl: "-2.75"},
  {power: "1", axis: "50", cyl: "-2.75"},
  {power: "1.25", axis: "50", cyl: "-2.75"},
  {power: "1.5", axis: "50", cyl: "-2.75"},
  {power: "1.75", axis: "50", cyl: "-2.75"},
  {power: "2", axis: "50", cyl: "-2.75"},
  {power: "2.25", axis: "50", cyl: "-2.75"},
  {power: "2.5", axis: "50", cyl: "-2.75"},
  {power: "2.75", axis: "50", cyl: "-2.75"},
  {power: "3", axis: "50", cyl: "-2.75"},
  {power: "3.25", axis: "50", cyl: "-2.75"},
  {power: "3.5", axis: "50", cyl: "-2.75"},
  {power: "3.75", axis: "50", cyl: "-2.75"},
  {power: "4", axis: "50", cyl: "-2.75"},
  {power: "4.25", axis: "50", cyl: "-2.75"},
  {power: "4.5", axis: "50", cyl: "-2.75"},
  {power: "4.75", axis: "50", cyl: "-2.75"},
  {power: "5", axis: "50", cyl: "-2.75"},
  {power: "5.25", axis: "50", cyl: "-2.75"},
  {power: "5.5", axis: "50", cyl: "-2.75"},
  {power: "5.75", axis: "50", cyl: "-2.75"},
  {power: "6", axis: "50", cyl: "-2.75"},
  {power: "0", axis: "60", cyl: "-2.75"},
  {power: "-0.25", axis: "60", cyl: "-2.75"},
  {power: "-0.5", axis: "60", cyl: "-2.75"},
  {power: "-0.75", axis: "60", cyl: "-2.75"},
  {power: "-1", axis: "60", cyl: "-2.75"},
  {power: "-1.25", axis: "60", cyl: "-2.75"},
  {power: "-1.5", axis: "60", cyl: "-2.75"},
  {power: "-1.75", axis: "60", cyl: "-2.75"},
  {power: "-2", axis: "60", cyl: "-2.75"},
  {power: "-2.25", axis: "60", cyl: "-2.75"},
  {power: "-2.5", axis: "60", cyl: "-2.75"},
  {power: "-2.75", axis: "60", cyl: "-2.75"},
  {power: "-3", axis: "60", cyl: "-2.75"},
  {power: "-3.25", axis: "60", cyl: "-2.75"},
  {power: "-3.5", axis: "60", cyl: "-2.75"},
  {power: "-3.75", axis: "60", cyl: "-2.75"},
  {power: "-4", axis: "60", cyl: "-2.75"},
  {power: "-4.25", axis: "60", cyl: "-2.75"},
  {power: "-4.5", axis: "60", cyl: "-2.75"},
  {power: "-4.75", axis: "60", cyl: "-2.75"},
  {power: "-5", axis: "60", cyl: "-2.75"},
  {power: "-5.25", axis: "60", cyl: "-2.75"},
  {power: "-5.5", axis: "60", cyl: "-2.75"},
  {power: "-5.75", axis: "60", cyl: "-2.75"},
  {power: "-6", axis: "60", cyl: "-2.75"},
  {power: "-6.5", axis: "60", cyl: "-2.75"},
  {power: "-7", axis: "60", cyl: "-2.75"},
  {power: "-7.5", axis: "60", cyl: "-2.75"},
  {power: "-8", axis: "60", cyl: "-2.75"},
  {power: "-8.5", axis: "60", cyl: "-2.75"},
  {power: "-9", axis: "60", cyl: "-2.75"},
  {power: "0.25", axis: "60", cyl: "-2.75"},
  {power: "0.5", axis: "60", cyl: "-2.75"},
  {power: "0.75", axis: "60", cyl: "-2.75"},
  {power: "1", axis: "60", cyl: "-2.75"},
  {power: "1.25", axis: "60", cyl: "-2.75"},
  {power: "1.5", axis: "60", cyl: "-2.75"},
  {power: "1.75", axis: "60", cyl: "-2.75"},
  {power: "2", axis: "60", cyl: "-2.75"},
  {power: "2.25", axis: "60", cyl: "-2.75"},
  {power: "2.5", axis: "60", cyl: "-2.75"},
  {power: "2.75", axis: "60", cyl: "-2.75"},
  {power: "3", axis: "60", cyl: "-2.75"},
  {power: "3.25", axis: "60", cyl: "-2.75"},
  {power: "3.5", axis: "60", cyl: "-2.75"},
  {power: "3.75", axis: "60", cyl: "-2.75"},
  {power: "4", axis: "60", cyl: "-2.75"},
  {power: "4.25", axis: "60", cyl: "-2.75"},
  {power: "4.5", axis: "60", cyl: "-2.75"},
  {power: "4.75", axis: "60", cyl: "-2.75"},
  {power: "5", axis: "60", cyl: "-2.75"},
  {power: "5.25", axis: "60", cyl: "-2.75"},
  {power: "5.5", axis: "60", cyl: "-2.75"},
  {power: "5.75", axis: "60", cyl: "-2.75"},
  {power: "6", axis: "60", cyl: "-2.75"},
  {power: "-6.5", axis: "70", cyl: "-2.75"},
  {power: "-7", axis: "70", cyl: "-2.75"},
  {power: "-7.5", axis: "70", cyl: "-2.75"},
  {power: "-8", axis: "70", cyl: "-2.75"},
  {power: "-8.5", axis: "70", cyl: "-2.75"},
  {power: "-9", axis: "70", cyl: "-2.75"},
  {power: "0.25", axis: "70", cyl: "-2.75"},
  {power: "0.5", axis: "70", cyl: "-2.75"},
  {power: "0.75", axis: "70", cyl: "-2.75"},
  {power: "1", axis: "70", cyl: "-2.75"},
  {power: "1.25", axis: "70", cyl: "-2.75"},
  {power: "1.5", axis: "70", cyl: "-2.75"},
  {power: "1.75", axis: "70", cyl: "-2.75"},
  {power: "2", axis: "70", cyl: "-2.75"},
  {power: "2.25", axis: "70", cyl: "-2.75"},
  {power: "2.5", axis: "70", cyl: "-2.75"},
  {power: "2.75", axis: "70", cyl: "-2.75"},
  {power: "3", axis: "70", cyl: "-2.75"},
  {power: "3.25", axis: "70", cyl: "-2.75"},
  {power: "3.5", axis: "70", cyl: "-2.75"},
  {power: "3.75", axis: "70", cyl: "-2.75"},
  {power: "4", axis: "70", cyl: "-2.75"},
  {power: "4.25", axis: "70", cyl: "-2.75"},
  {power: "4.5", axis: "70", cyl: "-2.75"},
  {power: "4.75", axis: "70", cyl: "-2.75"},
  {power: "5", axis: "70", cyl: "-2.75"},
  {power: "5.25", axis: "70", cyl: "-2.75"},
  {power: "5.5", axis: "70", cyl: "-2.75"},
  {power: "5.75", axis: "70", cyl: "-2.75"},
  {power: "6", axis: "70", cyl: "-2.75"},
  {power: "-6.5", axis: "80", cyl: "-2.75"},
  {power: "-7", axis: "80", cyl: "-2.75"},
  {power: "-7.5", axis: "80", cyl: "-2.75"},
  {power: "-8", axis: "80", cyl: "-2.75"},
  {power: "-8.5", axis: "80", cyl: "-2.75"},
  {power: "-9", axis: "80", cyl: "-2.75"},
  {power: "0.25", axis: "80", cyl: "-2.75"},
  {power: "0.5", axis: "80", cyl: "-2.75"},
  {power: "0.75", axis: "80", cyl: "-2.75"},
  {power: "1", axis: "80", cyl: "-2.75"},
  {power: "1.25", axis: "80", cyl: "-2.75"},
  {power: "1.5", axis: "80", cyl: "-2.75"},
  {power: "1.75", axis: "80", cyl: "-2.75"},
  {power: "2", axis: "80", cyl: "-2.75"},
  {power: "2.25", axis: "80", cyl: "-2.75"},
  {power: "2.5", axis: "80", cyl: "-2.75"},
  {power: "2.75", axis: "80", cyl: "-2.75"},
  {power: "3", axis: "80", cyl: "-2.75"},
  {power: "3.25", axis: "80", cyl: "-2.75"},
  {power: "3.5", axis: "80", cyl: "-2.75"},
  {power: "3.75", axis: "80", cyl: "-2.75"},
  {power: "4", axis: "80", cyl: "-2.75"},
  {power: "4.25", axis: "80", cyl: "-2.75"},
  {power: "4.5", axis: "80", cyl: "-2.75"},
  {power: "4.75", axis: "80", cyl: "-2.75"},
  {power: "5", axis: "80", cyl: "-2.75"},
  {power: "5.25", axis: "80", cyl: "-2.75"},
  {power: "5.5", axis: "80", cyl: "-2.75"},
  {power: "5.75", axis: "80", cyl: "-2.75"},
  {power: "6", axis: "80", cyl: "-2.75"},
  {power: "-6.5", axis: "90", cyl: "-2.75"},
  {power: "-7", axis: "90", cyl: "-2.75"},
  {power: "-7.5", axis: "90", cyl: "-2.75"},
  {power: "-8", axis: "90", cyl: "-2.75"},
  {power: "-8.5", axis: "90", cyl: "-2.75"},
  {power: "-9", axis: "90", cyl: "-2.75"},
  {power: "0.25", axis: "90", cyl: "-2.75"},
  {power: "0.5", axis: "90", cyl: "-2.75"},
  {power: "0.75", axis: "90", cyl: "-2.75"},
  {power: "1", axis: "90", cyl: "-2.75"},
  {power: "1.25", axis: "90", cyl: "-2.75"},
  {power: "1.5", axis: "90", cyl: "-2.75"},
  {power: "1.75", axis: "90", cyl: "-2.75"},
  {power: "2", axis: "90", cyl: "-2.75"},
  {power: "2.25", axis: "90", cyl: "-2.75"},
  {power: "2.5", axis: "90", cyl: "-2.75"},
  {power: "2.75", axis: "90", cyl: "-2.75"},
  {power: "3", axis: "90", cyl: "-2.75"},
  {power: "3.25", axis: "90", cyl: "-2.75"},
  {power: "3.5", axis: "90", cyl: "-2.75"},
  {power: "3.75", axis: "90", cyl: "-2.75"},
  {power: "4", axis: "90", cyl: "-2.75"},
  {power: "4.25", axis: "90", cyl: "-2.75"},
  {power: "4.5", axis: "90", cyl: "-2.75"},
  {power: "4.75", axis: "90", cyl: "-2.75"},
  {power: "5", axis: "90", cyl: "-2.75"},
  {power: "5.25", axis: "90", cyl: "-2.75"},
  {power: "5.5", axis: "90", cyl: "-2.75"},
  {power: "5.75", axis: "90", cyl: "-2.75"},
  {power: "6", axis: "90", cyl: "-2.75"},
  {power: "-6.5", axis: "100", cyl: "-2.75"},
  {power: "-7", axis: "100", cyl: "-2.75"},
  {power: "-7.5", axis: "100", cyl: "-2.75"},
  {power: "-8", axis: "100", cyl: "-2.75"},
  {power: "-8.5", axis: "100", cyl: "-2.75"},
  {power: "-9", axis: "100", cyl: "-2.75"},
  {power: "0.25", axis: "100", cyl: "-2.75"},
  {power: "0.5", axis: "100", cyl: "-2.75"},
  {power: "0.75", axis: "100", cyl: "-2.75"},
  {power: "1", axis: "100", cyl: "-2.75"},
  {power: "1.25", axis: "100", cyl: "-2.75"},
  {power: "1.5", axis: "100", cyl: "-2.75"},
  {power: "1.75", axis: "100", cyl: "-2.75"},
  {power: "2", axis: "100", cyl: "-2.75"},
  {power: "2.25", axis: "100", cyl: "-2.75"},
  {power: "2.5", axis: "100", cyl: "-2.75"},
  {power: "2.75", axis: "100", cyl: "-2.75"},
  {power: "3", axis: "100", cyl: "-2.75"},
  {power: "3.25", axis: "100", cyl: "-2.75"},
  {power: "3.5", axis: "100", cyl: "-2.75"},
  {power: "3.75", axis: "100", cyl: "-2.75"},
  {power: "4", axis: "100", cyl: "-2.75"},
  {power: "4.25", axis: "100", cyl: "-2.75"},
  {power: "4.5", axis: "100", cyl: "-2.75"},
  {power: "4.75", axis: "100", cyl: "-2.75"},
  {power: "5", axis: "100", cyl: "-2.75"},
  {power: "5.25", axis: "100", cyl: "-2.75"},
  {power: "5.5", axis: "100", cyl: "-2.75"},
  {power: "5.75", axis: "100", cyl: "-2.75"},
  {power: "6", axis: "100", cyl: "-2.75"},
  {power: "-6.5", axis: "110", cyl: "-2.75"},
  {power: "-7", axis: "110", cyl: "-2.75"},
  {power: "-7.5", axis: "110", cyl: "-2.75"},
  {power: "-8", axis: "110", cyl: "-2.75"},
  {power: "-8.5", axis: "110", cyl: "-2.75"},
  {power: "-9", axis: "110", cyl: "-2.75"},
  {power: "0.25", axis: "110", cyl: "-2.75"},
  {power: "0.5", axis: "110", cyl: "-2.75"},
  {power: "0.75", axis: "110", cyl: "-2.75"},
  {power: "1", axis: "110", cyl: "-2.75"},
  {power: "1.25", axis: "110", cyl: "-2.75"},
  {power: "1.5", axis: "110", cyl: "-2.75"},
  {power: "1.75", axis: "110", cyl: "-2.75"},
  {power: "2", axis: "110", cyl: "-2.75"},
  {power: "2.25", axis: "110", cyl: "-2.75"},
  {power: "2.5", axis: "110", cyl: "-2.75"},
  {power: "2.75", axis: "110", cyl: "-2.75"},
  {power: "3", axis: "110", cyl: "-2.75"},
  {power: "3.25", axis: "110", cyl: "-2.75"},
  {power: "3.5", axis: "110", cyl: "-2.75"},
  {power: "3.75", axis: "110", cyl: "-2.75"},
  {power: "4", axis: "110", cyl: "-2.75"},
  {power: "4.25", axis: "110", cyl: "-2.75"},
  {power: "4.5", axis: "110", cyl: "-2.75"},
  {power: "4.75", axis: "110", cyl: "-2.75"},
  {power: "5", axis: "110", cyl: "-2.75"},
  {power: "5.25", axis: "110", cyl: "-2.75"},
  {power: "5.5", axis: "110", cyl: "-2.75"},
  {power: "5.75", axis: "110", cyl: "-2.75"},
  {power: "6", axis: "110", cyl: "-2.75"},
  {power: "0", axis: "120", cyl: "-2.75"},
  {power: "-0.25", axis: "120", cyl: "-2.75"},
  {power: "-0.5", axis: "120", cyl: "-2.75"},
  {power: "-0.75", axis: "120", cyl: "-2.75"},
  {power: "-1", axis: "120", cyl: "-2.75"},
  {power: "-1.25", axis: "120", cyl: "-2.75"},
  {power: "-1.5", axis: "120", cyl: "-2.75"},
  {power: "-1.75", axis: "120", cyl: "-2.75"},
  {power: "-2", axis: "120", cyl: "-2.75"},
  {power: "-2.25", axis: "120", cyl: "-2.75"},
  {power: "-2.5", axis: "120", cyl: "-2.75"},
  {power: "-2.75", axis: "120", cyl: "-2.75"},
  {power: "-3", axis: "120", cyl: "-2.75"},
  {power: "-3.25", axis: "120", cyl: "-2.75"},
  {power: "-3.5", axis: "120", cyl: "-2.75"},
  {power: "-3.75", axis: "120", cyl: "-2.75"},
  {power: "-4", axis: "120", cyl: "-2.75"},
  {power: "-4.25", axis: "120", cyl: "-2.75"},
  {power: "-4.5", axis: "120", cyl: "-2.75"},
  {power: "-4.75", axis: "120", cyl: "-2.75"},
  {power: "-5", axis: "120", cyl: "-2.75"},
  {power: "-5.25", axis: "120", cyl: "-2.75"},
  {power: "-5.5", axis: "120", cyl: "-2.75"},
  {power: "-5.75", axis: "120", cyl: "-2.75"},
  {power: "-6", axis: "120", cyl: "-2.75"},
  {power: "-6.5", axis: "120", cyl: "-2.75"},
  {power: "-7", axis: "120", cyl: "-2.75"},
  {power: "-7.5", axis: "120", cyl: "-2.75"},
  {power: "-8", axis: "120", cyl: "-2.75"},
  {power: "-8.5", axis: "120", cyl: "-2.75"},
  {power: "-9", axis: "120", cyl: "-2.75"},
  {power: "0.25", axis: "120", cyl: "-2.75"},
  {power: "0.5", axis: "120", cyl: "-2.75"},
  {power: "0.75", axis: "120", cyl: "-2.75"},
  {power: "1", axis: "120", cyl: "-2.75"},
  {power: "1.25", axis: "120", cyl: "-2.75"},
  {power: "1.5", axis: "120", cyl: "-2.75"},
  {power: "1.75", axis: "120", cyl: "-2.75"},
  {power: "2", axis: "120", cyl: "-2.75"},
  {power: "2.25", axis: "120", cyl: "-2.75"},
  {power: "2.5", axis: "120", cyl: "-2.75"},
  {power: "2.75", axis: "120", cyl: "-2.75"},
  {power: "3", axis: "120", cyl: "-2.75"},
  {power: "3.25", axis: "120", cyl: "-2.75"},
  {power: "3.5", axis: "120", cyl: "-2.75"},
  {power: "3.75", axis: "120", cyl: "-2.75"},
  {power: "4", axis: "120", cyl: "-2.75"},
  {power: "4.25", axis: "120", cyl: "-2.75"},
  {power: "4.5", axis: "120", cyl: "-2.75"},
  {power: "4.75", axis: "120", cyl: "-2.75"},
  {power: "5", axis: "120", cyl: "-2.75"},
  {power: "5.25", axis: "120", cyl: "-2.75"},
  {power: "5.5", axis: "120", cyl: "-2.75"},
  {power: "5.75", axis: "120", cyl: "-2.75"},
  {power: "6", axis: "120", cyl: "-2.75"},
  {power: "0", axis: "130", cyl: "-2.75"},
  {power: "-0.25", axis: "130", cyl: "-2.75"},
  {power: "-0.5", axis: "130", cyl: "-2.75"},
  {power: "-0.75", axis: "130", cyl: "-2.75"},
  {power: "-1", axis: "130", cyl: "-2.75"},
  {power: "-1.25", axis: "130", cyl: "-2.75"},
  {power: "-1.5", axis: "130", cyl: "-2.75"},
  {power: "-1.75", axis: "130", cyl: "-2.75"},
  {power: "-2", axis: "130", cyl: "-2.75"},
  {power: "-2.25", axis: "130", cyl: "-2.75"},
  {power: "-2.5", axis: "130", cyl: "-2.75"},
  {power: "-2.75", axis: "130", cyl: "-2.75"},
  {power: "-3", axis: "130", cyl: "-2.75"},
  {power: "-3.25", axis: "130", cyl: "-2.75"},
  {power: "-3.5", axis: "130", cyl: "-2.75"},
  {power: "-3.75", axis: "130", cyl: "-2.75"},
  {power: "-4", axis: "130", cyl: "-2.75"},
  {power: "-4.25", axis: "130", cyl: "-2.75"},
  {power: "-4.5", axis: "130", cyl: "-2.75"},
  {power: "-4.75", axis: "130", cyl: "-2.75"},
  {power: "-5", axis: "130", cyl: "-2.75"},
  {power: "-5.25", axis: "130", cyl: "-2.75"},
  {power: "-5.5", axis: "130", cyl: "-2.75"},
  {power: "-5.75", axis: "130", cyl: "-2.75"},
  {power: "-6", axis: "130", cyl: "-2.75"},
  {power: "-6.5", axis: "130", cyl: "-2.75"},
  {power: "-7", axis: "130", cyl: "-2.75"},
  {power: "-7.5", axis: "130", cyl: "-2.75"},
  {power: "-8", axis: "130", cyl: "-2.75"},
  {power: "-8.5", axis: "130", cyl: "-2.75"},
  {power: "-9", axis: "130", cyl: "-2.75"},
  {power: "0.25", axis: "130", cyl: "-2.75"},
  {power: "0.5", axis: "130", cyl: "-2.75"},
  {power: "0.75", axis: "130", cyl: "-2.75"},
  {power: "1", axis: "130", cyl: "-2.75"},
  {power: "1.25", axis: "130", cyl: "-2.75"},
  {power: "1.5", axis: "130", cyl: "-2.75"},
  {power: "1.75", axis: "130", cyl: "-2.75"},
  {power: "2", axis: "130", cyl: "-2.75"},
  {power: "2.25", axis: "130", cyl: "-2.75"},
  {power: "2.5", axis: "130", cyl: "-2.75"},
  {power: "2.75", axis: "130", cyl: "-2.75"},
  {power: "3", axis: "130", cyl: "-2.75"},
  {power: "3.25", axis: "130", cyl: "-2.75"},
  {power: "3.5", axis: "130", cyl: "-2.75"},
  {power: "3.75", axis: "130", cyl: "-2.75"},
  {power: "4", axis: "130", cyl: "-2.75"},
  {power: "4.25", axis: "130", cyl: "-2.75"},
  {power: "4.5", axis: "130", cyl: "-2.75"},
  {power: "4.75", axis: "130", cyl: "-2.75"},
  {power: "5", axis: "130", cyl: "-2.75"},
  {power: "5.25", axis: "130", cyl: "-2.75"},
  {power: "5.5", axis: "130", cyl: "-2.75"},
  {power: "5.75", axis: "130", cyl: "-2.75"},
  {power: "6", axis: "130", cyl: "-2.75"},
  {power: "0", axis: "140", cyl: "-2.75"},
  {power: "-0.25", axis: "140", cyl: "-2.75"},
  {power: "-0.5", axis: "140", cyl: "-2.75"},
  {power: "-0.75", axis: "140", cyl: "-2.75"},
  {power: "-1", axis: "140", cyl: "-2.75"},
  {power: "-1.25", axis: "140", cyl: "-2.75"},
  {power: "-1.5", axis: "140", cyl: "-2.75"},
  {power: "-1.75", axis: "140", cyl: "-2.75"},
  {power: "-2", axis: "140", cyl: "-2.75"},
  {power: "-2.25", axis: "140", cyl: "-2.75"},
  {power: "-2.5", axis: "140", cyl: "-2.75"},
  {power: "-2.75", axis: "140", cyl: "-2.75"},
  {power: "-3", axis: "140", cyl: "-2.75"},
  {power: "-3.25", axis: "140", cyl: "-2.75"},
  {power: "-3.5", axis: "140", cyl: "-2.75"},
  {power: "-3.75", axis: "140", cyl: "-2.75"},
  {power: "-4", axis: "140", cyl: "-2.75"},
  {power: "-4.25", axis: "140", cyl: "-2.75"},
  {power: "-4.5", axis: "140", cyl: "-2.75"},
  {power: "-4.75", axis: "140", cyl: "-2.75"},
  {power: "-5", axis: "140", cyl: "-2.75"},
  {power: "-5.25", axis: "140", cyl: "-2.75"},
  {power: "-5.5", axis: "140", cyl: "-2.75"},
  {power: "-5.75", axis: "140", cyl: "-2.75"},
  {power: "-6", axis: "140", cyl: "-2.75"},
  {power: "-6.5", axis: "140", cyl: "-2.75"},
  {power: "-7", axis: "140", cyl: "-2.75"},
  {power: "-7.5", axis: "140", cyl: "-2.75"},
  {power: "-8", axis: "140", cyl: "-2.75"},
  {power: "-8.5", axis: "140", cyl: "-2.75"},
  {power: "-9", axis: "140", cyl: "-2.75"},
  {power: "0.25", axis: "140", cyl: "-2.75"},
  {power: "0.5", axis: "140", cyl: "-2.75"},
  {power: "0.75", axis: "140", cyl: "-2.75"},
  {power: "1", axis: "140", cyl: "-2.75"},
  {power: "1.25", axis: "140", cyl: "-2.75"},
  {power: "1.5", axis: "140", cyl: "-2.75"},
  {power: "1.75", axis: "140", cyl: "-2.75"},
  {power: "2", axis: "140", cyl: "-2.75"},
  {power: "2.25", axis: "140", cyl: "-2.75"},
  {power: "2.5", axis: "140", cyl: "-2.75"},
  {power: "2.75", axis: "140", cyl: "-2.75"},
  {power: "3", axis: "140", cyl: "-2.75"},
  {power: "3.25", axis: "140", cyl: "-2.75"},
  {power: "3.5", axis: "140", cyl: "-2.75"},
  {power: "3.75", axis: "140", cyl: "-2.75"},
  {power: "4", axis: "140", cyl: "-2.75"},
  {power: "4.25", axis: "140", cyl: "-2.75"},
  {power: "4.5", axis: "140", cyl: "-2.75"},
  {power: "4.75", axis: "140", cyl: "-2.75"},
  {power: "5", axis: "140", cyl: "-2.75"},
  {power: "5.25", axis: "140", cyl: "-2.75"},
  {power: "5.5", axis: "140", cyl: "-2.75"},
  {power: "5.75", axis: "140", cyl: "-2.75"},
  {power: "6", axis: "140", cyl: "-2.75"},
  {power: "0", axis: "150", cyl: "-2.75"},
  {power: "-0.25", axis: "150", cyl: "-2.75"},
  {power: "-0.5", axis: "150", cyl: "-2.75"},
  {power: "-0.75", axis: "150", cyl: "-2.75"},
  {power: "-1", axis: "150", cyl: "-2.75"},
  {power: "-1.25", axis: "150", cyl: "-2.75"},
  {power: "-1.5", axis: "150", cyl: "-2.75"},
  {power: "-1.75", axis: "150", cyl: "-2.75"},
  {power: "-2", axis: "150", cyl: "-2.75"},
  {power: "-2.25", axis: "150", cyl: "-2.75"},
  {power: "-2.5", axis: "150", cyl: "-2.75"},
  {power: "-2.75", axis: "150", cyl: "-2.75"},
  {power: "-3", axis: "150", cyl: "-2.75"},
  {power: "-3.25", axis: "150", cyl: "-2.75"},
  {power: "-3.5", axis: "150", cyl: "-2.75"},
  {power: "-3.75", axis: "150", cyl: "-2.75"},
  {power: "-4", axis: "150", cyl: "-2.75"},
  {power: "-4.25", axis: "150", cyl: "-2.75"},
  {power: "-4.5", axis: "150", cyl: "-2.75"},
  {power: "-4.75", axis: "150", cyl: "-2.75"},
  {power: "-5", axis: "150", cyl: "-2.75"},
  {power: "-5.25", axis: "150", cyl: "-2.75"},
  {power: "-5.5", axis: "150", cyl: "-2.75"},
  {power: "-5.75", axis: "150", cyl: "-2.75"},
  {power: "-6", axis: "150", cyl: "-2.75"},
  {power: "-6.5", axis: "150", cyl: "-2.75"},
  {power: "-7", axis: "150", cyl: "-2.75"},
  {power: "-7.5", axis: "150", cyl: "-2.75"},
  {power: "-8", axis: "150", cyl: "-2.75"},
  {power: "-8.5", axis: "150", cyl: "-2.75"},
  {power: "-9", axis: "150", cyl: "-2.75"},
  {power: "0.25", axis: "150", cyl: "-2.75"},
  {power: "0.5", axis: "150", cyl: "-2.75"},
  {power: "0.75", axis: "150", cyl: "-2.75"},
  {power: "1", axis: "150", cyl: "-2.75"},
  {power: "1.25", axis: "150", cyl: "-2.75"},
  {power: "1.5", axis: "150", cyl: "-2.75"},
  {power: "1.75", axis: "150", cyl: "-2.75"},
  {power: "2", axis: "150", cyl: "-2.75"},
  {power: "2.25", axis: "150", cyl: "-2.75"},
  {power: "2.5", axis: "150", cyl: "-2.75"},
  {power: "2.75", axis: "150", cyl: "-2.75"},
  {power: "3", axis: "150", cyl: "-2.75"},
  {power: "3.25", axis: "150", cyl: "-2.75"},
  {power: "3.5", axis: "150", cyl: "-2.75"},
  {power: "3.75", axis: "150", cyl: "-2.75"},
  {power: "4", axis: "150", cyl: "-2.75"},
  {power: "4.25", axis: "150", cyl: "-2.75"},
  {power: "4.5", axis: "150", cyl: "-2.75"},
  {power: "4.75", axis: "150", cyl: "-2.75"},
  {power: "5", axis: "150", cyl: "-2.75"},
  {power: "5.25", axis: "150", cyl: "-2.75"},
  {power: "5.5", axis: "150", cyl: "-2.75"},
  {power: "5.75", axis: "150", cyl: "-2.75"},
  {power: "6", axis: "150", cyl: "-2.75"},
  {power: "0.25", axis: "160", cyl: "-2.75"},
  {power: "0.5", axis: "160", cyl: "-2.75"},
  {power: "0.75", axis: "160", cyl: "-2.75"},
  {power: "1", axis: "160", cyl: "-2.75"},
  {power: "1.25", axis: "160", cyl: "-2.75"},
  {power: "1.5", axis: "160", cyl: "-2.75"},
  {power: "1.75", axis: "160", cyl: "-2.75"},
  {power: "2", axis: "160", cyl: "-2.75"},
  {power: "2.25", axis: "160", cyl: "-2.75"},
  {power: "2.5", axis: "160", cyl: "-2.75"},
  {power: "2.75", axis: "160", cyl: "-2.75"},
  {power: "3", axis: "160", cyl: "-2.75"},
  {power: "3.25", axis: "160", cyl: "-2.75"},
  {power: "3.5", axis: "160", cyl: "-2.75"},
  {power: "3.75", axis: "160", cyl: "-2.75"},
  {power: "4", axis: "160", cyl: "-2.75"},
  {power: "4.25", axis: "160", cyl: "-2.75"},
  {power: "4.5", axis: "160", cyl: "-2.75"},
  {power: "4.75", axis: "160", cyl: "-2.75"},
  {power: "5", axis: "160", cyl: "-2.75"},
  {power: "5.25", axis: "160", cyl: "-2.75"},
  {power: "5.5", axis: "160", cyl: "-2.75"},
  {power: "5.75", axis: "160", cyl: "-2.75"},
  {power: "6", axis: "160", cyl: "-2.75"},
  {power: "0.25", axis: "170", cyl: "-2.75"},
  {power: "0.5", axis: "170", cyl: "-2.75"},
  {power: "0.75", axis: "170", cyl: "-2.75"},
  {power: "1", axis: "170", cyl: "-2.75"},
  {power: "1.25", axis: "170", cyl: "-2.75"},
  {power: "1.5", axis: "170", cyl: "-2.75"},
  {power: "1.75", axis: "170", cyl: "-2.75"},
  {power: "2", axis: "170", cyl: "-2.75"},
  {power: "2.25", axis: "170", cyl: "-2.75"},
  {power: "2.5", axis: "170", cyl: "-2.75"},
  {power: "2.75", axis: "170", cyl: "-2.75"},
  {power: "3", axis: "170", cyl: "-2.75"},
  {power: "3.25", axis: "170", cyl: "-2.75"},
  {power: "3.5", axis: "170", cyl: "-2.75"},
  {power: "3.75", axis: "170", cyl: "-2.75"},
  {power: "4", axis: "170", cyl: "-2.75"},
  {power: "4.25", axis: "170", cyl: "-2.75"},
  {power: "4.5", axis: "170", cyl: "-2.75"},
  {power: "4.75", axis: "170", cyl: "-2.75"},
  {power: "5", axis: "170", cyl: "-2.75"},
  {power: "5.25", axis: "170", cyl: "-2.75"},
  {power: "5.5", axis: "170", cyl: "-2.75"},
  {power: "5.75", axis: "170", cyl: "-2.75"},
  {power: "6", axis: "170", cyl: "-2.75"},
  {power: "0.25", axis: "180", cyl: "-2.75"},
  {power: "0.5", axis: "180", cyl: "-2.75"},
  {power: "0.75", axis: "180", cyl: "-2.75"},
  {power: "1", axis: "180", cyl: "-2.75"},
  {power: "1.25", axis: "180", cyl: "-2.75"},
  {power: "1.5", axis: "180", cyl: "-2.75"},
  {power: "1.75", axis: "180", cyl: "-2.75"},
  {power: "2", axis: "180", cyl: "-2.75"},
  {power: "2.25", axis: "180", cyl: "-2.75"},
  {power: "2.5", axis: "180", cyl: "-2.75"},
  {power: "2.75", axis: "180", cyl: "-2.75"},
  {power: "3", axis: "180", cyl: "-2.75"},
  {power: "3.25", axis: "180", cyl: "-2.75"},
  {power: "3.5", axis: "180", cyl: "-2.75"},
  {power: "3.75", axis: "180", cyl: "-2.75"},
  {power: "4", axis: "180", cyl: "-2.75"},
  {power: "4.25", axis: "180", cyl: "-2.75"},
  {power: "4.5", axis: "180", cyl: "-2.75"},
  {power: "4.75", axis: "180", cyl: "-2.75"},
  {power: "5", axis: "180", cyl: "-2.75"},
  {power: "5.25", axis: "180", cyl: "-2.75"},
  {power: "5.5", axis: "180", cyl: "-2.75"},
  {power: "5.75", axis: "180", cyl: "-2.75"},
  {power: "6", axis: "180", cyl: "-2.75"}
]